import React from 'react';

import styles from './index.module.scss';

import { EcommPlatform } from 'components';
import { ReactComponent as RrFavicon } from 'assets/img/brand/rr-favicon.svg';
import { ReactComponent as BoltIcon } from 'assets/img/icons/bolt.svg';

export default function StoreConnectHeader({ store = '' }) {
  return (
    <div className={styles.container}>
      <EcommPlatform.Icon source={store} className={styles.icon} />
      <div className={styles.separator} />
      <BoltIcon className={styles.boltIcon} />
      <div className={styles.separator} />
      <RrFavicon className={styles.icon} />
    </div>
  );
}
