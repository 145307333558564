import React from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardBody, CardTitle } from 'reactstrap';
import cn from 'classnames';
import styles from './index.module.scss';

import { If } from 'utils';
import { ROUTES } from '../../stubs';
import { ReactComponent as BackIcon } from 'assets/img/icons/previous-page.svg';

export default function ContainerCard({
  title = 'Connect with Return Rabbit',
  children = null,
  onBackClick = () => {},
  className = ''
}) {
  const { pathname } = useLocation();

  const isRootPage = pathname === ROUTES.ROOT;

  return (
    <Card className={cn(styles.card, className)}>
      <CardBody className={styles.body}>
        <div className={styles.header}>
          <If test={!isRootPage}>
            <div role="button" onClick={onBackClick} className={styles.backBtn}>
              <BackIcon /> Back
            </div>
          </If>
          <CardTitle className={styles.title}>{title}</CardTitle>
        </div>
        {children}
      </CardBody>
    </Card>
  );
}
