import React from 'react';
import { Formik } from 'formik';
import _get from 'lodash/get';

import Form from './Form';
import { useConnectStore } from 'views/ConnectStore/context/ConnectStoreContext';
import { STORE_URL_PREFIX, STORE_URL_SUFIX } from 'views/ConnectStore/stubs';

export default function WoocommerceConnect() {
  const { isConnecting, connect } = useConnectStore();

  const onSubmit = values => {
    const storeUrl = _get(values, 'storeUrl', '');
    if (!storeUrl) return;

    connect({
      shop: `${STORE_URL_PREFIX.WOOCOMMERCE}${storeUrl}${STORE_URL_SUFIX.WOOCOMMERCE}`
    });
  };

  return (
    <Formik
      initialValues={{ storeUrl: '' }}
      onSubmit={onSubmit}
      render={props => {
        return <Form {...props} isProcessing={isConnecting} />;
      }}
    />
  );
}
