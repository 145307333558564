import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';

import Skeleton from 'components/Skeleton';

const SSR_SEPARATOR = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="angles-right"
      className="svg-inline--fa fa-angles-right icon icon separator-kud5xf7"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      height={10}
      width={10}
    >
      <path
        fill="currentColor"
        d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z"
      />
    </svg>
  );
};

export default function Breadcrumb({ items = [], separator = '>' }) {
  if (!_isArray(items) || _isEmpty(items)) return null;

  const listItems = items.map((item, index) => {
    const { label, onClick, loading = false, shepherdId } = item;
    return (
      <li
        key={uuidv4()}
        className="customBreadcrumb--item"
        data-separator={separator}
        data-shepherd={shepherdId}
      >
        {loading ? (
          <Skeleton width="100px" height="16px" className="mb-0" />
        ) : (
          <button
            onClick={onClick}
            disabled={loading || !onClick}
            className="customBreadcrumb--item__button"
          >
            {label}
          </button>
        )}
        {index < items.length - 1 && <SSR_SEPARATOR />}
      </li>
    );
  });

  return <ul className="customBreadcrumb">{listItems}</ul>;
}
