import React, { useContext } from 'react';
import _get from 'lodash/get';

import { Card, Cell } from '../../components';
import { AuthContext } from 'context/AuthContext';

export default function AccountDetails() {
  const { accountInfo } = useContext(AuthContext);

  const accountName = _get(accountInfo, 'name', '');

  return (
    <Card>
      <Card.Title>Account Details</Card.Title>
      <Cell isMuted label="Account Name">
        {accountName}
      </Cell>
    </Card>
  );
}
