import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import cn from 'classnames';
import moment from 'moment';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If } from 'utils';
import { useNotifications } from 'utils/hooks';
import NotificationIcon from '../NotificationIcon';
import { doActionTemplateId, actionIdMap } from '../../methods';
import {
  COLOR_CODES,
  HOVER_COLOR_CODES,
  ICON_BG_COLOR_CODES,
  ICON_COLOR
} from '../../stubs';
import { ReactComponent as MarkReadIcon } from 'assets/img/icons/new/mark_read_icon.svg';

const variantBox = {
  hidden: {
    opacity: 0,
    // y: -200,
    height: 0,
    marginBottom: 0,
    transition: {
      duration: 0.7,
      delay: 0.1
    }
  },
  show: {
    opacity: 1,
    // y: 0,
    height: 'auto',
    marginBottom: 12,
    transition: {
      duration: 0.1
    }
  }
};

const Notification = ({ details = {}, markReadCb = () => {} }) => {
  const [hoverOver, setHoverOver] = useState(false);
  const { setDropdownVisibility } = useNotifications();
  const { push: redirectFn } = useHistory();
  const id = _get(details, 'id', '');
  const type = _get(details, 'category', '');
  const title = _get(details, 'title', '');
  const subtitle = _get(details, 'description', '');
  const date = _get(details, 'created_at', '');
  const isRead = _get(details, 'is_read', '');
  const actionLabel = _get(details, 'action_label', '');
  const actionId = _get(details, 'action_id', '');
  const ifNotifcationActionExists =
    actionLabel && actionId && actionIdMap[actionId];
  const formatedDate = date ? moment(date).format('MM/DD/YY') : '';

  const notificationStyle = {
    backgroundColor: !isRead
      ? hoverOver
        ? HOVER_COLOR_CODES[type]
        : COLOR_CODES[type]
      : 'white',
    borderColor: 'var(--gray-100)',
    cursor: ifNotifcationActionExists ? 'pointer' : 'auto'
  };

  const markReadIconStyle = {
    fill: ICON_COLOR[type],
    backgroundColor: ICON_BG_COLOR_CODES[type]
  };

  const onMarkAsRead = e => {
    e.stopPropagation();
    markReadCb(id);
  };

  const doAction = () => {
    if (!ifNotifcationActionExists) return;
    doActionTemplateId(actionId, { redirectFn });
    if (!isRead) {
      markReadCb(id);
    }
    setDropdownVisibility(false);
  };

  const notificationBodyClassname = cn(styles.notificationBody, {
    [styles.readNotification]: isRead,
    [styles.onHoverOpacity]: hoverOver && ifNotifcationActionExists && isRead
  });

  return (
    <motion.div
      className="box"
      variants={variantBox}
      initial="hidden"
      animate="show"
      exit="hidden"
      onClick={doAction}
      onMouseOver={() => setHoverOver(true)}
      onMouseOut={() => setHoverOver(false)}
    >
      <div className={notificationBodyClassname} style={notificationStyle}>
        <NotificationIcon type={type} />
        <div className={styles.notificationContent}>
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
          <If test={ifNotifcationActionExists}>
            <div className="d-flex align-items-center">
              <span className={styles.actionLabel}>{actionLabel}</span>
              <span className={styles.arrow}></span>
            </div>
          </If>
        </div>
        <div className={styles.actionCol}>
          <span>
            <If test={!isRead}>
              <span className={styles.markReadIcon} style={markReadIconStyle}>
                <MarkReadIcon onClick={onMarkAsRead} />
              </span>
            </If>
          </span>
          <span className={styles.date}>{formatedDate}</span>
        </div>
      </div>
    </motion.div>
  );
};

export default Notification;
