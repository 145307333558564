import React, { useContext } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown
} from 'reactstrap';
import cn from 'classnames';
import _get from 'lodash/get';
import _size from 'lodash/size';
import styles from './index.module.scss';

import { If, isSSRTheme } from 'utils';
import { EcommPlatform } from 'components';
import { AuthContext } from 'context/AuthContext';

export default function StoreSelect({
  onSelect = () => {},
  className = '',
  toggleClassName = '',
  menuClassName = '',
  menuItemClassName = ''
}) {
  const { storeProperties, permittedStores } = useContext(AuthContext);

  const storeName = _get(storeProperties, 'store_unique_name', '');
  const hasMultipleStoreAccess = _size(permittedStores) > 1;

  const { storesList, storePlatformIcon } = permittedStores.reduce(
    (acc, { name, rr_store_id, seller_store_guid, ecommerce_platform }) => {
      const storeId = isSSRTheme() ? seller_store_guid : rr_store_id;
      if (name === storeName) {
        acc.storePlatformIcon = ecommerce_platform;
      } else {
        acc.storesList.push(
          <DropdownItem
            key={storeId}
            className={cn(styles.item, menuItemClassName)}
            onClick={() => onSelect({ id: storeId, name })}
          >
            <EcommPlatform.Icon
              source={ecommerce_platform}
              className={styles.eCommerceIcon}
            />
            {name}
          </DropdownItem>
        );
      }
      return acc;
    },
    { storesList: [], storePlatformIcon: null }
  );

  return (
    <UncontrolledButtonDropdown className={className}>
      <DropdownToggle
        nav
        caret={hasMultipleStoreAccess}
        className={cn(styles.userInfo, toggleClassName, {
          [styles.empty]: !hasMultipleStoreAccess
        })}
      >
        <EcommPlatform.Icon
          source={storePlatformIcon}
          className={styles.eCommerceIcon}
        />
        <span>{storeName}</span>
      </DropdownToggle>
      <If test={hasMultipleStoreAccess}>
        <DropdownMenu className={menuClassName}>
          <DropdownItem
            // key="static-option"
            className={styles.disabled}
          >
            Switch to another store:
          </DropdownItem>
          <div className={styles.container}>{storesList}</div>
        </DropdownMenu>
      </If>
    </UncontrolledButtonDropdown>
  );
}
