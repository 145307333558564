import React, { useState } from 'react';
import { Alert, Card, CardBody, Button, CardTitle } from 'reactstrap';
import qs from 'qs';
import _get from 'lodash/get';

import { If } from 'utils';
import { resetPassword } from 'utils/services';
import { PasswordStrength } from 'components';
import { useLocation } from 'react-router-dom';

export default function ResetPassworForm() {
  const { search } = useLocation();

  const [isSuccess, setIsSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordStrong, setIsPasswordStrong] = useState(false);

  const queryParams = qs.parse(search, { ignoreQueryPrefix: true });
  const email = _get(queryParams, 'email', '');

  const disableInputs = isProcessing || isSuccess !== null;

  const changePassword = () => {
    if (password === '' || confirmPassword === '') {
      return setError('Please enter password and confirm password');
    }
    if (password.length < 6) {
      return setError('Password must be atleast 6 characters long.');
    }
    if (password !== confirmPassword) {
      return setError('Passwords do not match.');
    }

    setIsProcessing(true);
    resetPassword(
      {
        ...queryParams,
        password: password,
        confirmed_password: confirmPassword
      },
      () => setIsSuccess(true),
      () => setIsSuccess(false),
      () => setIsProcessing(false)
    );
  };

  const handlePasswordInput = e => {
    setError(null);
    setPassword(e.target.value);
  };

  const confirmPasswordInput = e => {
    setError(null);
    setConfirmPassword(e.target.value);
  };

  return (
    <Card className="login-card">
      <CardBody className="p-0">
        <CardTitle className="text-center">Change your password</CardTitle>
        <div>
          <form>
            <div className="form-group">
              <input
                disabled={true}
                type="text"
                name="email"
                id="exampleInputEmail1"
                value={email}
                placeholder="Email"
                aria-describedby="emailHelp"
                className="form-control login-input"
              />
            </div>
            <div className="form-group">
              <input
                className="form-control login-input"
                name="email"
                id="exampleInputEmail1"
                type="password"
                aria-describedby="emailHelp"
                placeholder="New Password"
                onChange={handlePasswordInput}
                value={password}
                disabled={disableInputs}
              />
            </div>
            <PasswordStrength
              password={password}
              onChange={setIsPasswordStrong}
            />
            <div className="form-group">
              <input
                className="form-control login-input"
                name="email"
                id="exampleInputEmail1"
                type="password"
                aria-describedby="emailHelp"
                placeholder="Confirm New Password"
                onChange={confirmPasswordInput}
                value={confirmPassword}
                disabled={disableInputs}
              />
            </div>
            <If test={isProcessing === false}>
              <Alert color={isSuccess ? 'success' : 'danger'}>
                {isSuccess
                  ? 'Your password has been reset successfully. Please login with your new password.'
                  : "Something went wrong. Please check if you've already used the link to reset password."}
              </Alert>
            </If>
            <If test={error}>
              <Alert color="danger">{error}</Alert>
            </If>
            <div className="form-group">
              <Button
                style={{ width: '100%' }}
                color="primary"
                type="button"
                className="login-button"
                onClick={changePassword}
                disabled={isSuccess || disableInputs || !isPasswordStrong}
              >
                {isProcessing
                  ? 'Processing...'
                  : isSuccess === true
                  ? 'Done'
                  : isSuccess === false
                  ? 'Error'
                  : 'Reset Password '}
              </Button>
            </div>
          </form>
        </div>
      </CardBody>
    </Card>
  );
}
