import React, { useContext } from 'react';

import Observer from './Observer';
import NotFound from './NotFound';
import ErrorView from './ErrorView';
import { AuthContext } from 'context/AuthContext';

export default function ErrorBoundary({
  homeButton = false,
  allowRefresh = true,
  children = null
}) {
  const { hasError, errorObject } = useContext(AuthContext);

  return (
    <Observer
      hasError={hasError}
      errorObject={errorObject}
      homeButton={homeButton}
      allowRefresh={allowRefresh}
    >
      {children}
    </Observer>
  );
}

ErrorBoundary.Observer = Observer;
ErrorBoundary.NotFound = NotFound;
ErrorBoundary.ErrorView = ErrorView;
