import React, { useMemo } from 'react';
import { If } from 'utils';

const LineBreak = ({
  color = '#e4e5e7',
  centerLabel = '',
  disabled = false,
  borderStyle = 'solid',
  borderSize = '1px',
  marginTop = '',
  marginBottom = '',
  marginLeft = '',
  marginRight = ''
}) => {
  const labelParent = useMemo(() => {
    return {
      position: 'relative',
      backgroundColor: `${disabled ? 'inherit' : 'white'}`
    };
  }, [disabled]);

  const labelCenter = useMemo(() => {
    return {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: `${disabled ? 'inherit' : 'white'}`,
      padding: '0 10px'
    };
  }, [disabled]);

  const hrStyle = useMemo(() => {
    return {
      borderTop: `${borderSize} ${borderStyle}`,
      borderColor: color,
      marginTop: marginTop,
      marginBottom: marginBottom,
      marginLeft: marginLeft,
      marginRight: marginRight
    };
  }, [
    borderSize,
    borderStyle,
    color,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight
  ]);

  return (
    <div style={labelParent}>
      <If test={centerLabel}>
        <b style={labelCenter}>{centerLabel}</b>
      </If>
      <hr style={hrStyle} />
    </div>
  );
};

export default LineBreak;
