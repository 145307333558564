import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import DefaultHeader from './DefaultHeader';

export default function Header() {
  return (
    <header className={styles.container}>
      <div className={styles.content}>
        <div className={cn(styles.wrapper, styles.default)}>
          <DefaultHeader />
        </div>
      </div>
    </header>
  );
}
