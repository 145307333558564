import { CustomLazyLoad } from 'utils';
import RETEX_ADMIN_ROUTES from 'config/nav/admin/routes';
import SHIPMENT_TRACKING_ADMIN_ROUTES from 'config/nav/shipmentTrackingApp/routes';

const Overview = CustomLazyLoad(() => import('views/Overview'));
const Orders = CustomLazyLoad(() => import('views/Orders'));
const Requests = CustomLazyLoad(() => import('views/Requests'));
const Analytics = CustomLazyLoad(() => import('views/Analytics'));
const Configure = CustomLazyLoad(() => import('views/Configure'));
const Settings = CustomLazyLoad(() => import('views/Settings'));
const HelpAndSupport = CustomLazyLoad(() => import('views/HelpAndSupport'));
const Warehouse = CustomLazyLoad(() => import('views/Warehouse'));
const StoreReturns = CustomLazyLoad(() => import('views/StoreReturns'));
const TrackingRabbitHome = CustomLazyLoad(() =>
  import('views/TrackingRabbitHome')
);
const ShipmentTrackingPage = CustomLazyLoad(() =>
  import('views/ShipmentTrackingPage')
);
const EmailTemplates = CustomLazyLoad(() =>
  import('views/Configure/ShopperPortal/views/EmailTemplates')
);

const RETEX_APP_ADMIN_ROUTES = [
  {
    id: 'admin.submenu.overview.enabled',
    role: 'admin',
    path: RETEX_ADMIN_ROUTES.OVERVIEW.ROOT,
    name: 'Overview',
    component: Overview,
    hideInSSR: true
  },
  {
    id: 'admin.submenu.orders.enabled',
    role: 'admin',
    path: RETEX_ADMIN_ROUTES.ORDERS.ROOT,
    name: 'Orders',
    component: Orders,
    hideInSSR: true
  },
  {
    id: 'admin.submenu.requests.enabled',
    role: 'admin',
    path: RETEX_ADMIN_ROUTES.REQUESTS.ROOT,
    name: 'Requests',
    component: Requests,
    hideInSSR: true
  },
  {
    id: 'admin.submenu.configure.enabled',
    role: 'admin',
    path: RETEX_ADMIN_ROUTES.CONFIGURE_RR.ROOT,
    name: 'Configure',
    component: Configure,
    hideInSSR: false
  },
  {
    id: 'admin.submenu.analytics.enabled',
    role: 'admin',
    path: RETEX_ADMIN_ROUTES.ANALYTICS.ROOT,
    name: 'Analytics',
    component: Analytics,
    hideInSSR: false
  },
  {
    id: 'admin.submenu.settings.enabled',
    role: 'admin',
    path: RETEX_ADMIN_ROUTES.SETTINGS.ROOT,
    name: 'Settings',
    component: Settings,
    hideInSSR: true
  },
  {
    id: 'admin.submenu.help_and_support.enabled',
    role: 'admin',
    path: RETEX_ADMIN_ROUTES.HELP_AND_SUPPORT.ROOT,
    name: 'Help',
    component: HelpAndSupport,
    hideInSSR: false
  }
];

const WAREHOUSE_ROUTES = [
  {
    id: 'warehouse.enabled',
    role: 'warehouse',
    path: '/w',
    name: 'Warehouse',
    component: Warehouse
  }
];

const STORE_ROUTES = [
  {
    id: 'store.enabled',
    role: 'store',
    path: '/s',
    name: 'Store Returns',
    component: StoreReturns
  }
];

export const RETEX_APP_ROUTES = [
  ...RETEX_APP_ADMIN_ROUTES,
  ...WAREHOUSE_ROUTES,
  ...STORE_ROUTES
];

export const TRACKING_APP_ROUTES = [
  {
    id: 'admin.submenu.home.enabled',
    role: 'admin',
    path: SHIPMENT_TRACKING_ADMIN_ROUTES.HOME.ROOT,
    name: 'Home',
    component: TrackingRabbitHome
  },
  {
    id: 'admin.submenu.orders.enabled',
    role: 'admin',
    path: RETEX_ADMIN_ROUTES.ORDERS.ROOT,
    name: 'Orders',
    component: Orders
  },
  {
    id: 'admin.submenu.tracking.enabled',
    role: 'admin',
    path: SHIPMENT_TRACKING_ADMIN_ROUTES.TRACKING.ROOT,
    name: 'Tracking Page',
    component: ShipmentTrackingPage
  },
  {
    id: 'admin.submenu.emails.enabled',
    role: 'admin',
    path: SHIPMENT_TRACKING_ADMIN_ROUTES.EMAILS.ROOT,
    name: 'Email Templates',
    component: EmailTemplates
  },
  {
    id: 'admin.submenu.settings.enabled',
    role: 'admin',
    path: RETEX_ADMIN_ROUTES.SETTINGS.ROOT,
    name: 'Settings',
    component: Settings
  },
  {
    id: 'admin.submenu.help_and_support.enabled',
    role: 'admin',
    path: RETEX_ADMIN_ROUTES.HELP_AND_SUPPORT.ROOT,
    name: 'Help',
    component: HelpAndSupport
  }
];
