import React from 'react';
import Spinner from 'assets/img/icons/spinner.svg';

const SSRLoader = () => (
  <div className="spinner-ssr">
    <img src={Spinner} alt="spinner" />
  </div>
);

export default SSRLoader;
