import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import _get from 'lodash/get';
import _toLower from 'lodash/toLower';

import { If } from 'utils';
import { UiKit } from 'components';
import { AuthContext } from 'context/AuthContext';
import { useCountdown, useStoreSwitch } from 'utils/hooks';
import ADMIN_ROUTUES from 'config/nav/admin/routes';

const COUNTDOWN_TIME = 10;

export default function ConnectionRedirect() {
  const { replace } = useHistory();
  const { search } = useLocation();

  const queryParams = qs.parse(search, { ignoreQueryPrefix: true });
  const id = _get(queryParams, 'rr_store_id', null);
  const name = _get(queryParams, 'store_name', null);
  const appInstall = _get(queryParams, 'app_install', null);

  const isAppInstall = _toLower(appInstall) === 'true';
  const isOpen = !!id && isAppInstall;

  const { isError, isProcessing, onStoreSwitch } = useStoreSwitch();
  const { accountInfo } = useContext(AuthContext);
  const canSwitchRef = useRef(true);

  const accountName = _get(accountInfo, 'name', '');

  const { seconds, isDone, pause } = useCountdown(COUNTDOWN_TIME, {
    autoStart: isOpen
  });

  const switchStore = useCallback(() => {
    pause();
    canSwitchRef.current = false;
    onStoreSwitch({ id, name });
  }, [id, name, pause, onStoreSwitch]);

  useEffect(() => {
    if (isDone && !isProcessing && canSwitchRef.current) {
      switchStore();
    }
  }, [isDone, isProcessing, switchStore]);

  const onCancel = useCallback(() => {
    pause();
    canSwitchRef.current = true;
    replace(ADMIN_ROUTUES.ACCOUNT_SETTINGS.ROOT);
  }, [pause, replace]);

  return (
    <UiKit.Modal
      isOpen={isOpen}
      backdrop="static"
      isCrossIconHidden={!isError}
      isCancelBtnHidden={!isError}
      title="Store Connected"
      onClose={onCancel}
      onConfirm={switchStore}
      isProcessing={isProcessing}
      confirmBtnText="Start Onboarding"
      confirmBtnProcessingText="Getting ready..."
    >
      <p>
        Congratulations! The store <strong>{name}</strong> is now connected to
        the <strong>{accountName}</strong> account. Let's get the store
        onboarded.
      </p>
      <p className="mb-0">
        <If
          test={!isError}
          otherwise="Something not right! Please complete the onboarding later."
        >
          You will be redirected to the store onboarding{' '}
          <If test={!isDone && !isProcessing} otherwise="now">
            in <strong>{seconds} seconds</strong>
          </If>
        </If>
        .
      </p>
    </UiKit.Modal>
  );
}
