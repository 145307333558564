import React, { useRef } from 'react';
import { Switch, Route } from 'react-router-dom';
import { NexusProvider } from '@return-rabbit/nexus';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import aphroditeInterface from 'react-with-styles-interface-aphrodite';
import 'react-dates/initialize';
import '@coreui/icons/css/coreui-icons.min.css';
import 'ladda/dist/ladda-themeless.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import './scss/style.css';

import { logError } from 'utils';
import {
  LoginRoute,
  LogoutRoute,
  ProtectedRoute,
  UnProtectedRoute,
  OnboardingRoute,
  ForcePasswordChangeRoute
} from './utils/routes';
import {
  ConnectLayout,
  DefaultLayout,
  LoginLayout,
  EmptyLayout,
  ExternalSignIn,
  ShipStationConnect
} from './containers';
import { DeploymentAlert, ErrorBoundary } from 'components';
import NotSupportedBrowser from './NotSupportedBrowser';
import ForceChangePassword from 'views/ForceChangePassword';
import { SellerSignIn } from 'containers';
import { isSSRTheme } from 'utils';
import { useNexusConfg } from 'utils/hooks';
// import subscribeToPusher from './config/setup/__DEPRECATED__subscribeToPusher';

ThemedStyleSheet.registerInterface(aphroditeInterface);

export default function App() {
  const { axiosConfig, masterData, storeProperties } = useNexusConfg();

  const isNotSupported = useRef(
    navigator.appName === 'Microsoft Internet Explorer' ||
      !!(
        navigator.userAgent.match(/Trident/) ||
        navigator.userAgent.match(/rv:11/)
      )
  ).current;

  if (isNotSupported) {
    return <NotSupportedBrowser />;
  }

  const isSSRMode = isSSRTheme();

  const rrRoutes = isSSRMode
    ? [
        <Route
          key={'seller-signin'}
          path="/seller-signin"
          component={SellerSignIn}
        />
      ]
    : [
        <Route
          key={'external-signin'}
          exact
          path="/signin"
          component={ExternalSignIn}
        />,
        <LoginRoute
          key={'login'}
          exact
          path="/login"
          component={LoginLayout}
        />,
        <Route
          key={'ss-connect'}
          path="/ss-connect"
          component={ShipStationConnect}
        />,
        <UnProtectedRoute
          key={'connect-store'}
          path="/connect-store"
          component={ConnectLayout}
        />,
        <UnProtectedRoute
          key={'reset-password'}
          path="/reset-password"
          component={LoginLayout}
        />,
        <OnboardingRoute
          key={'onboarding'}
          path="/onboarding"
          component={EmptyLayout}
        />,
        <ForcePasswordChangeRoute
          key={'force-password-change'}
          path="/password-change"
          component={ForceChangePassword}
        />
      ];

  return (
    <ErrorBoundary>
      <NexusProvider
        mode="RR-ADMIN"
        axiosConfig={axiosConfig}
        masterData={masterData}
        storeProperties={storeProperties}
        logErrror={logError}
      >
        <DeploymentAlert />
        <Switch>
          <LogoutRoute path="/logout" />
          {rrRoutes}
          <ProtectedRoute
            isSSRMode={isSSRMode}
            path="/"
            component={DefaultLayout}
          />
        </Switch>
      </NexusProvider>
    </ErrorBoundary>
  );
}
