import React from 'react';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If } from 'utils';
import { EcommPlatform, UiKit } from 'components';
import { TableRows, TableRow } from 'components/Tables';
import { ReactComponent as ExternalLinkIcon } from 'assets/img/sidebar/customer-portal.svg';

export default function Rows({
  stores = [],
  processingId = '',
  isDisabled = false,
  onStoreSwitch = () => {}
}) {
  const rows = stores.map(store => {
    const id = _get(store, 'id', '');
    const name = _get(store, 'name', '');
    const title = _get(store, 'title', '');
    const storeUrl = _get(store, 'url', '');
    const ecommercePlan = _get(store, 'ecommerce_plan', '');
    const ecommPlatform = _get(store, 'ecommerce_platform', '');
    const isOnboarded = _get(store, 'onboarding_completed', false);
    const isProcessing = processingId === id;

    const values = [
      {
        lg: 4,
        customClass: 'd-flex align-items-center',
        CustomComponent: (
          <div className={styles.cell}>
            <EcommPlatform.Icon
              source={ecommPlatform}
              className={styles.icon}
            />
            <strong>{title}</strong>
          </div>
        )
      },
      {
        lg: 4,
        CustomComponent: (
          <a
            href={storeUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.cell}
          >
            <span className="text-muted">{storeUrl}</span>
            <ExternalLinkIcon className={styles.linkIcon} />
          </a>
        )
      },
      {
        lg: 4,
        CustomComponent: (
          <div className={styles.row}>
            <p className="text-muted text-capitalize m-0">{ecommercePlan}</p>
            <If test={!isOnboarded}>
              <UiKit.Button
                customType="success"
                onClick={() => onStoreSwitch({ id, name })}
                disabled={isDisabled}
                processing={isProcessing}
                processingText="Redirecting..."
                className={styles.btn}
              >
                Complete Onboarding
              </UiKit.Button>
            </If>
          </div>
        )
      }
    ];
    return <TableRow key={id} values={values} />;
  });

  return <TableRows>{rows}</TableRows>;
}
