import React, { useContext } from 'react';
import _get from 'lodash/get';

import { UiKit } from 'components';
import { Card, Cell } from '../../components';
import { AuthContext } from 'context/AuthContext';

export default function UserDetails() {
  const { userData, isExternalUser, toggleChangePasswordModal } = useContext(
    AuthContext
  );

  const email = _get(userData, 'email', '');

  return (
    <Card>
      <Card.Title>User Details</Card.Title>
      <UiKit.GridRow gap="24" className="align-items-end">
        <Cell isMuted label="Email">
          {email}
        </Cell>
        <Cell contentClassName="p-0">
          <UiKit.Button
            customType="outline"
            onClick={toggleChangePasswordModal}
            disabled={isExternalUser}
            className="w-100"
          >
            Change Password
          </UiKit.Button>
        </Cell>
      </UiKit.GridRow>
    </Card>
  );
}
