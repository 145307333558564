const COPIES = {
  onHold: {
    body: '',
    header: 'Lock Request',
    title: 'Are you sure you want to lock this request ?',
    subtitle:
      'This will lock all items in this request for any further action. You will still be able to unlock the request anytime later.',
    buttonTitle: 'Confirm',
    processingButtonTitle: 'Locking...'
  },
  offHold: {
    body: '',
    header: 'Unlock Request',
    title: 'Are you sure you want to unlock this request?',
    subtitle:
      'This will unlock all items in this request for any further action.',
    buttonTitle: 'Confirm',
    processingButtonTitle: 'Unlocking...'
  },
  cancel: {
    body: '',
    header: 'Cancel Request',
    title: 'Are you sure you want to cancel this request?',
    subtitle:
      'This will cancel all items in the request. Please be careful. This is a permanent change and you will not be able to undo this.',
    buttonTitle: 'Cancel Request',
    processingButtonTitle: 'Cancelling...'
  }
};

export default COPIES;
