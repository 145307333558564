import React, { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import _isFunction from 'lodash/isFunction';

import { useStoreConnection } from 'utils/hooks';
import { AlertBanner, ContainerCard, StoreConnectHeader } from '../components';

const ConnectStoreContext = createContext({
  referrer: '',
  trialPeriod: '',
  isConnecting: false,
  connect: (_connectData = {}) => false,
  isError: false,
  errorMessage: ''
});

/**
 * @param {Object} props
 * @param {import("utils/types").EcommPlatformSource} props.store
 * @param {boolean} props.isConnecting
 * @param {function(Record<string, string>): void} props.onCancel
 * @param {function(Record<string, string>): void} props.onConnect
 * @param {string} props.className
 * @param {import("react").ReactNode} props.children
 */
export function ConnectStoreProvider({
  store = '',
  isConnecting = false,
  onCancel = () => {},
  onConnect = undefined,
  className = '',
  children = null
}) {
  const { search } = useLocation();

  const { isProcessing, onInitiateConnect } = useStoreConnection();

  const { referrer, free_trial: trialPeriod, error, msg } = qs.parse(
    search.slice(1)
  );
  const isError = error === '1';
  const errorMessage = isError && msg ? msg : '';

  const connect = (data = null) => {
    const queryData = { ...data };
    if (referrer && trialPeriod) {
      queryData.referrer = referrer;
      queryData.trial_period = trialPeriod;
    }
    const connector = _isFunction(onConnect) ? onConnect : onInitiateConnect;
    connector(store, queryData);
  };

  const goBack = () => onCancel({ referrer, free_trial: trialPeriod });

  return (
    <ConnectStoreContext.Provider
      value={{
        connect,
        isConnecting: isProcessing || isConnecting,
        referrer,
        trialPeriod,
        isError,
        errorMessage
      }}
    >
      <AlertBanner />
      <ContainerCard
        title={<StoreConnectHeader store={store} />}
        onBackClick={goBack}
        className={className}
      >
        {children}
      </ContainerCard>
    </ConnectStoreContext.Provider>
  );
}

export function useConnectStore() {
  const context = useContext(ConnectStoreContext);

  if (!context) {
    throw new Error(
      'useConnectStore must be used within a ConnectContextProvider'
    );
  }

  return context;
}
