/**
 * @type {Record<import("utils/types").EcommPlatformSource, string>}
 */
export const STORE_URL_PREFIX = {
  SHOPIFY: '',
  BIGCOMMERCE: 'https://',
  WOOCOMMERCE: ''
};

/**
 * @type {Record<import("utils/types").EcommPlatformSource, string>}
 */
export const STORE_URL_SUFIX = {
  SHOPIFY: '.myshopify.com',
  BIGCOMMERCE: '.mybigcommerce.com',
  WOOCOMMERCE: ''
};
