import React from 'react';
import { Field, Form } from 'formik';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import RetailerStoreSelection from './RetailerSelect/index';

const FormField = ({
  name,
  type,
  placeholder,
  disabled,
  autoFocus,
  error,
  touched,
  handleChange
}) => (
  <div className="form-group">
    <Field
      className={`form-control login-input${
        error && touched ? ' is-invalid' : ''
      }`}
      onChange={handleChange}
      name={name}
      id={`input-${name}`}
      type={type}
      aria-describedby={`${name}Help`}
      placeholder={placeholder}
      disabled={disabled}
      autoFocus={autoFocus}
    />
    <div className="invalid-feedback">{error}</div>
  </div>
);

const LADDA_BUTTON_PROPS = {
  'data-style': SLIDE_UP,
  'data-spinner-size': 30,
  'data-spinner-color': '#ddd',
  'data-spinner-lines': 12
};

const StepTwoForm = ({
  list = [],
  values,
  setValues,
  errors,
  touched,
  handleChange,
  loading
}) => {
  return (
    <Form>
      <FormField
        name="email"
        type="email"
        placeholder="Email"
        disabled={true}
        error={errors.email}
        touched={touched.email}
        handleChange={handleChange}
      />

      <RetailerStoreSelection
        list={list}
        values={values}
        setValues={setValues}
        errors={errors}
      />

      <FormField
        name="password"
        type="password"
        placeholder="Password"
        disabled={loading}
        autoFocus={!list.length}
        error={errors.password}
        touched={touched.password}
        handleChange={handleChange}
      />

      <LaddaButton
        type="submit"
        className="btn btn-block btn-primary login-button"
        loading={loading}
        {...LADDA_BUTTON_PROPS}
      >
        Login
      </LaddaButton>
    </Form>
  );
};

export default React.memo(StepTwoForm);
