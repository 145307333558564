import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { LineBreak, UiKit } from 'components';

export default function SectionContainer({
  shepherdId = '',
  title = '',
  containerClassName = '',
  className = '',
  disabled = false,
  children = null
}) {
  return (
    <UiKit.GridRow
      templateColumns="1fr"
      gap={24}
      className={cn(styles.container, containerClassName)}
      data-shepherd={shepherdId}
    >
      <LineBreak borderStyle="dashed" marginTop={0} marginBottom={0} />
      {title && <h5 className={styles.title}>{title}</h5>}
      <div data-disabled={disabled} className={cn(styles.content, className)}>
        {children}
      </div>
    </UiKit.GridRow>
  );
}
