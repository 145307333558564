import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { If } from 'utils';
import { refreshSite } from 'utils/methods';
import notFoundImage from 'assets/rr-illustrations/lost.png';

export default function ErrorView({
  homeButton = false,
  allowRefresh = false,
  title = 'Error - Something went wrong',
  message = 'Something went wrong. Please retry or check your connection.\n If the error persists, please contact support',
  imageContainerClassName = '',
  imageClassName = '',
  testClassName = '',
  buttonClassName = ''
}) {
  return (
    <div className="ErrorPage">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={cn('ErrorPage__image', imageContainerClassName)}>
        <img src={notFoundImage} alt="not found" className={imageClassName} />
      </div>
      <div className={cn('ErrorPage__text', testClassName)}>{message}</div>
      <If test={allowRefresh}>
        <button
          onClick={refreshSite}
          className={cn('ErrorPage__button', buttonClassName)}
        >
          Refresh this page
        </button>
      </If>
      <If test={homeButton}>
        <Link to="/">
          <button className={cn('ErrorPage__button', buttonClassName)}>
            Back To Home
          </button>
        </Link>
      </If>
    </div>
  );
}
