import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { If } from 'utils';
import { UiKit } from 'components';

export default function StoreSwitchModal({
  storeName = '',
  isOpen = false,
  isProcessing = false,
  isValidating = false,
  isSwitching = false,
  onClose = () => {}
}) {
  return (
    <UiKit.Modal
      centered
      isOpen={isOpen}
      onClose={onClose}
      isProcessing={isProcessing}
      contentClassName={cn(styles.modal, { [styles.expanded]: false })}
      headerClassName={styles.header}
    >
      <If test={isValidating || isSwitching}>
        <div className="d-flex align-items-center">
          {(isValidating || isSwitching) && (
            <i className="fas fa-spin fa-spinner" />
          )}
          <span className="ml-3">
            {isValidating ? (
              'Verifying access...'
            ) : isSwitching ? (
              <>
                Switching to <strong>{storeName}</strong>
              </>
            ) : (
              ''
            )}
          </span>
        </div>
      </If>
      {/* <If test={needPassword && toStore}>
          <form className={styles.loginForm} onSubmit={onLogin}>
            <span className="mb-3">
              Login to <strong>{storeName}</strong>
              <LockIcon className={styles.lockIcon} />
            </span>

            <div className={styles.helptext}>
              <span role="img" aria-label="info">
                💡
              </span>
              <p>
                You need to provide your password and log in only once per store
                and then you can switch seamlessly between all logged in stores.
              </p>
            </div>

            <UiKit.Label
              className={styles.label}
              text={
                <>
                  Please enter the password for <strong>{storeName}</strong>{' '}
                  store
                </>
              }
            />
            <UiKit.Input
              type="password"
              placeholder="*************"
              inputRef={passwordInputRef}
              value={password}
              onChange={onPasswordChange}
              disabled={isLoggingIn}
              error={passwordError}
              className={styles.inputContainer}
            />
            <UiKit.Button
              customType="success"
              wrapperClassName={styles.loginButton}
              processing={isLoggingIn}
              processingText="Verifying..."
            >
              Login
            </UiKit.Button>
          </form>
        </If> */}
    </UiKit.Modal>
  );
}
