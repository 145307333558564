import React, { useRef, useCallback, useEffect } from 'react';
import _get from 'lodash/get';

import { logError } from 'utils';
import { useIdle, useStopwatch } from 'utils/hooks';
import { getPublishedDeploy } from 'utils/services/netlify';

const REVALIDATION_INTERVAL = 10; // seconds
const REVALIDATION_ERROR_THRESHOLD = 3; // times

export default function NetlifySync({ isEnabled = true }) {
  const isUserIdle = useIdle();
  const { seconds, isRunning, start, pause, reset } = useStopwatch();

  const netlifySyncFailureCount = useRef(0);

  const isProd = process.env.REACT_APP_MODE === 'PRODUCTION';
  const isWatching = isEnabled && !isUserIdle && isProd;

  const restart = useRef(() => {
    reset();
    start();
  }).current;

  const stop = useRef(() => {
    reset();
    pause();
  }).current;

  const validate = useCallback(() => {
    stop();

    if (netlifySyncFailureCount.current >= REVALIDATION_ERROR_THRESHOLD) return;

    getPublishedDeploy(
      deploy => {
        netlifySyncFailureCount.current = 0;
        const publishedBuildId = _get(deploy, 'build_id', '');
        const currentBuildId = process.env.REACT_APP_BUILD_ID;
        if (currentBuildId !== publishedBuildId) {
          window.location.reload();
        }
      },
      () => netlifySyncFailureCount.current++,
      () => {
        if (netlifySyncFailureCount.current >= REVALIDATION_ERROR_THRESHOLD) {
          logError(
            new Error('Aborting Netlify sync | Failed 3 times in a row')
          );
        } else {
          restart();
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isWatching) {
      validate();
    } else {
      stop();
    }
    return () => stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWatching, validate]);

  useEffect(() => {
    if (isRunning && seconds >= REVALIDATION_INTERVAL) {
      validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRunning, seconds]);

  useEffect(() => {
    if (isWatching) {
      const listener = () => {
        if (document.visibilityState === 'visible') {
          validate();
        } else {
          stop();
        }
      };

      window.addEventListener('visibilitychange', listener);
      return () => window.removeEventListener('visibilitychange', listener);
    }
  }, [isWatching, validate, stop]);

  return <></>;
}
