import React, { useCallback, useState } from 'react';

import { UiKit } from 'components';
import { TableHeading, TableAddButton } from 'components/Tables';
import {
  AccountDetails,
  ConnectStoreModal,
  StoresTable,
  UserDetails,
  ConnectionRedirect
} from './containers';

export default function AccountSettings() {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = useCallback(() => setIsOpen(false), []);

  return (
    <>
      <TableHeading
        title="Account Settings"
        helpText="Setup your defaults for Catalog Exchange. Choose among the product category, product types, collections and exclude products by tags or price."
        customClass="animated fadeIn"
      >
        <TableAddButton onClick={() => setIsOpen(true)}>
          Connect New Store
        </TableAddButton>
      </TableHeading>
      <UiKit.GridRow templateColumns="1fr" gap="24">
        <UiKit.GridRow templateColumns="1fr 2fr" gap="24">
          <AccountDetails />
          <UserDetails />
        </UiKit.GridRow>
        <StoresTable />
      </UiKit.GridRow>
      <ConnectStoreModal isOpen={isOpen} onClose={onClose} />
      <ConnectionRedirect />
    </>
  );
}
