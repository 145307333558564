import React from 'react';
import { Collapse } from 'reactstrap';
import styles from './index.module.scss';

import { UiKit } from 'components';

export default function StoreUrlInput({
  name = '',
  error = '',
  value = '',
  onBlur = () => {},
  onChange = () => {},
  label = 'Store Name',
  placeholder = 'Enter your store name',
  prefix = '',
  suffix = '',
  disablePreview = false
}) {
  const exampleURL = `${prefix}store-name${suffix}`;
  const storeUrl = value ? `${prefix}${value}${suffix}` : '';

  const tooltipHelptext = !disablePreview && (
    <>
      Store name can be found in your store URL which looks like:
      <br />
      <strong className="text-underline">
        <em>{exampleURL}</em>
      </strong>
    </>
  );

  return (
    <div className="form-group">
      <UiKit.Label text={label} helptext={tooltipHelptext} />
      <UiKit.GridRow
        templateColumns="1fr auto"
        gap="4"
        className={styles.container}
      >
        <UiKit.Input
          autoFocus
          name={name}
          error={error}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
        />
        {suffix && <strong>{suffix}</strong>}
      </UiKit.GridRow>
      <Collapse isOpen={!disablePreview && !!storeUrl}>
        <p className={styles.preview}>
          Store URL:{' '}
          <a href={storeUrl} target="_blank" rel="noopener noreferrer">
            {storeUrl}
          </a>
        </p>
      </Collapse>
    </div>
  );
}
