import React, { useEffect, useRef, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import uuid from 'uuid/v4';
import cn from 'classnames';
import styles from './index.module.scss';

/**
 * @param {Object} props
 * @param {string} [props.id='']
 * @param {React.ReactNode} [props.message='']
 * @param {string} [props.className='']
 * @param {React.ReactNode} [props.children=null]
 */
export default function Tooltip({
  id = '',
  message = '',
  className = '',
  children = null
}) {
  const uid = useRef(id || uuid()).current;
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <>
      <div id={uid} className={cn(styles.container, className)}>
        {children}
      </div>
      {isMounted && (
        <UncontrolledTooltip target={uid} placement="top">
          {message}
        </UncontrolledTooltip>
      )}
    </>
  );
}
