import React from 'react';
import styles from './index.module.scss';

import { TableRow } from 'components/Tables';
import { Skeleton } from 'components';

export default function Loader({ isLoading = false, children = null }) {
  if (!isLoading) return children;

  return Array.from({ length: 2 }, (_, index) => {
    const values = [
      {
        lg: 4,
        CustomComponent: (
          <div className={styles.cell}>
            <Skeleton className="m-0" width="32px" height="32px" />
            <Skeleton className="m-0" width="200px" height="18px" />
          </div>
        )
      },
      {
        lg: 4,
        CustomComponent: <Skeleton className="m-0" width="60%" height="18px" />
      },
      {
        lg: 4,
        CustomComponent: <Skeleton className="m-0" width="40%" height="18px" />
      }
    ];
    return <TableRow key={index} values={values} />;
  });
}
