import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import ReactSelect from 'react-select';
import Creatable from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';

import { If } from 'utils';
import ErrorMessage from './ErrorMessage';
import { getCustomSelectStyles, formatGroupLabel } from './methods';

export default function Select({
  label,
  error = '',
  isMulti = false,
  isAsync = false,
  isLoading = false,
  isCreatable = false,
  selectAllLabel = 'Select All',
  disableSelectAllOption = false,
  options,
  value,
  onChange = () => {},
  isDisabled = false,
  wrapperStyle = {},
  wrapperId = '',
  inputId = '',
  inputWrapperId = '',
  wrapperClassName = '',
  labelStyle = {},
  labelClassName = '',
  inputWrapperClassName = '',
  inputClassName = '',
  errorClassName = '',
  animate = null,
  overridingStyles = null,
  formatLabel = null,
  ...reactSelectProps
}) {
  const [isGrouped, setIsGrouped] = useState(false);
  const [allOptions, setAllOptions] = useState(options);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [animatedComponents, setAnimatedComponents] = useState(null);

  let HybridSelect = ReactSelect;
  if (isCreatable) {
    HybridSelect = Creatable;
  } else if (isAsync) {
    HybridSelect = AsyncSelect;
  }

  useEffect(() => {
    if (animate) {
      setAnimatedComponents(makeAnimated());
    }
  }, [animate]);

  useEffect(() => {
    if (options instanceof Array) {
      const { optionsList, isNested } = options.reduce(
        (acc, option) => {
          if (option.value) {
            acc.optionsList.push(option);
          } else if (option.options) {
            acc.isNested = true;
            acc.optionsList.push(...option.options);
          }
          return acc;
        },
        { optionsList: [], isNested: false }
      );
      setAllOptions(optionsList);
      setIsGrouped(isNested);
    } else {
    }
  }, [options]);

  const changeHandler = option => {
    let newOption = option;
    if (isMulti) {
      const optionsList = option ? [...option] : [];
      newOption = optionsList;
      const newlyAddedOption = optionsList[optionsList.length - 1];
      if (newlyAddedOption && newlyAddedOption.id === 'all-options') {
        setAreAllSelected(true);
        return onChange(newlyAddedOption.value); // all options
      } else {
        setAreAllSelected(false);
      }
      if (optionsList.length === allOptions.length) {
        setAreAllSelected(true);
      }
    }
    onChange(newOption);
  };

  let selectOptions = options || [];
  if (isMulti && !areAllSelected && Array.isArray(options) && options.length) {
    const optionsList = options ? options : [];
    if (!disableSelectAllOption) {
      selectOptions = [
        { label: selectAllLabel, value: allOptions, id: 'all-options' },
        ...optionsList
      ];
    } else {
      selectOptions = [...optionsList];
    }
  }
  const selectStyle =
    overridingStyles || getCustomSelectStyles({ error, isGrouped });

  const wrapClass = cn('uiKit__element', {
    [wrapperClassName]: !!wrapperClassName
  });
  const labelClass = cn('uiKit__element--labelWrapper', {
    [labelClassName]: !!labelClassName
  });
  const inputWrapperClass = cn('uiKit__select', {
    [inputWrapperClassName]: !!inputWrapperClassName
  });
  const inputClass = cn('uiKit__select__box', {
    [inputClassName]: !!inputClassName
  });

  return (
    <div className={wrapClass} style={wrapperStyle} id={wrapperId}>
      <If test={label}>
        <div className={labelClass}>
          <label style={labelStyle}>{label}</label>
        </div>
      </If>
      <div className={inputWrapperClass} id={inputWrapperId}>
        <HybridSelect
          id={inputId}
          value={value}
          isMulti={isMulti}
          styles={selectStyle}
          options={selectOptions}
          isLoading={isLoading}
          isDisabled={isLoading || isDisabled}
          formatGroupLabel={formatGroupLabel(isGrouped)}
          formatOptionLabel={formatLabel}
          components={animatedComponents}
          onChange={changeHandler}
          className={inputClass}
          classNamePrefix="uiKit__select__box"
          closeMenuOnSelect={!isMulti}
          {...reactSelectProps}
        />
        <ErrorMessage
          msg={error}
          className={errorClassName}
          hide={isDisabled}
        />
      </div>
    </div>
  );
}
