import React, { useEffect, useRef } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Container } from 'reactstrap';
import cn from 'classnames';
import _get from 'lodash/get';

import { TourGuides } from 'containers';
import { RETEX_APP_ROUTES, TRACKING_APP_ROUTES } from 'config/routes/global';
import { ErrorBoundary, Announcement, TermsAndConditions } from 'components';
import { OnlineCheck } from 'components/Common';
import AppLevelAlert from 'components/AppLevelAlert';
import Frame from './Frame';
import Sidebar from './Sidebar';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import ChangePassword from 'views/ChangePassword';
import AccountSettings from 'views/AccountSettings';
import ShopifySubscriptionModal from 'components/SubscriptionModal';
// import QuotaExhaustAlert from 'components/QuotaExhaustAlert';
// import WarehouseSetupAlert from 'components/WarehouseSetupAlert';
// import ScheduledMaintenanceAlert from 'components/ScheduledMaintenanceAlert';
import NotificationsBannerPortal from './NotificationsBannerPortal';
import { NotificationsProvider } from 'context/NotificationsContext';
import ADMIN_ROUTUES from 'config/nav/admin/routes';
import { useAppContext } from 'utils/hooks';
import LayoutWrapper from './LayoutWrapper';
import { If } from 'utils';

export default function DefaultLayout({
  ROLES,
  appType,
  configuration,
  location
}) {
  const { isSSRMode } = useAppContext();

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    document.body.classList.remove('header-fixed');
    return () => (document.body.style.overflowY = 'auto');
  }, []);

  const appRoutes = useRef(
    appType.shipmentTracking ? TRACKING_APP_ROUTES : RETEX_APP_ROUTES
  ).current;

  const redirectionRoutes = () => {
    const defaultRouteData = appRoutes.find(({ id, role, hideInSSR }) => {
      const isDefaultRole =
        role === ROLES.default && (!isSSRMode || (isSSRMode && !hideInSSR));
      const hasAccess = _get(configuration, id, false);
      return isDefaultRole && hasAccess;
    });

    const defaultRoute = _get(defaultRouteData, 'path', null);

    return defaultRoute ? <Redirect exact from="/" to={defaultRoute} /> : null;
  };

  const setupRoutes = () => {
    const mainRoutes = appRoutes.reduce((acc, route) => {
      if (appType.retex && (!route.component || !ROLES[route.role])) {
        return acc;
      } else if (appType.shipmentTracking && !route.component) {
        return acc;
      }
      const isRouteEnabled = !!_get(configuration, route.id, false);
      const isVisibleInSSR = !isSSRMode || (isSSRMode && !route.hideInSSR);

      const isAllowed = isRouteEnabled && isVisibleInSSR;
      if (isAllowed) {
        acc.push(
          <Route
            key={route.name}
            path={route.path}
            name={route.name}
            role={route.role}
            exact={!!route.exact}
            render={props => <route.component {...props} />}
          />
        );
      }
      return acc;
    }, []);
    const redirection = redirectionRoutes();

    const accountSettingsRoute = isSSRMode ? null : (
      <Route
        exact
        key="account-settings-route"
        path={ADMIN_ROUTUES.ACCOUNT_SETTINGS.ROOT}
        component={AccountSettings}
      />
    );

    return (
      <Switch>
        {mainRoutes}
        {accountSettingsRoute}
        {redirection}
        <Route exact path="/404" component={ErrorBoundary.NotFound} />
        <Redirect from="*" to="/404" />;
      </Switch>
    );
  };

  const containerClassName = cn('default-layout-container', {
    retexAppContainer: appType.retex,
    trackingAppContainer: appType.shipmentTracking,
    ssrAppContainer: isSSRMode
  });

  return (
    <ErrorBoundary>
      <NotificationsProvider>
        <TourGuides.WelcomeTour>
          <Frame>
            <Header />
            <LayoutWrapper>
              <Sidebar />
              <div className="app-body default-layout">
                <OnlineCheck />
                <AppLevelAlert />
                {/* <QuotaExhaustAlert /> */}
                {/* <WarehouseSetupAlert /> */}
                {/* <ScheduledMaintenanceAlert /> */}
                <Announcement.Container />
                <Content>
                  <NotificationsBannerPortal />
                  <Container fluid className={containerClassName}>
                    <ToastContainer className="app-toast-container" />
                    {setupRoutes()}
                  </Container>
                  <If test={!isSSRMode}>
                    <Footer />
                  </If>
                </Content>
                <ChangePassword />
                <ShopifySubscriptionModal />
                <TermsAndConditions.Modal />
              </div>
            </LayoutWrapper>
          </Frame>
        </TourGuides.WelcomeTour>
      </NotificationsProvider>
    </ErrorBoundary>
  );
}
