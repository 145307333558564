import React from 'react';
import _get from 'lodash/get';

import NavLinks from '../NavLinks';

export default function Nav({
  show = true,
  config = {},
  className = '',
  children = null
}) {
  if (!show) return null;

  const features = _get(config, 'items', []);

  return (
    <>
      <NavLinks features={features} className={className} />
      {children}
    </>
  );
}
