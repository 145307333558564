import React, { useCallback } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';
import qs from 'qs';
import _toLower from 'lodash/toLower';
import styles from './index.module.scss';

import { FreeTrialBanner, UiKit } from 'components';
import { Bigcommerce, Shopify, Woocommerce } from './pages';
import { ContainerCard, StoreTiles } from './components';
import { ConnectStoreProvider } from './context/ConnectStoreContext';
import { ROUTES } from './stubs';

function ConnectStore() {
  const { search } = useLocation();
  const { push } = useHistory();

  const { referrer, free_trial: trialPeriod } = qs.parse(search.slice(1));

  const onTileClick = useCallback(
    (ecommPlatform, { search }) => {
      const route = `${ROUTES.ROOT}/${_toLower(ecommPlatform)}${search}`;
      push(route);
    },
    [push]
  );

  const onBackToRoot = useCallback(
    queryParamsObj => {
      const queryParams = qs.stringify(queryParamsObj);
      const backRoute = `${ROUTES.ROOT}${queryParams ? `?${queryParams}` : ''}`;
      push(backRoute);
    },
    [push]
  );

  return (
    <UiKit.GridRow templateColumns="1fr" className={styles.container}>
      <FreeTrialBanner
        referrer={referrer}
        trialPeriod={trialPeriod}
        className={styles.banner}
      />
      <div className={styles.content}>
        <Switch>
          <Route exact path={ROUTES.SHOPIFY_CONNECT}>
            <ConnectStoreProvider store="SHOPIFY" onCancel={onBackToRoot}>
              <Shopify />
            </ConnectStoreProvider>
          </Route>
          <Route exact path={ROUTES.WOO_CONNECT}>
            <ConnectStoreProvider store="WOOCOMMERCE" onCancel={onBackToRoot}>
              <Woocommerce />
            </ConnectStoreProvider>
          </Route>
          <Route exact path={ROUTES.BIGCOMMERCE}>
            <ConnectStoreProvider store="BIGCOMMERCE" onCancel={onBackToRoot}>
              <Bigcommerce />
            </ConnectStoreProvider>
          </Route>
          <Route exact path={ROUTES.ROOT}>
            <ContainerCard title="Select your platform">
              <StoreTiles onClick={onTileClick} />
            </ContainerCard>
          </Route>
          <Redirect to={ROUTES.LOGIN} />
        </Switch>
      </div>
    </UiKit.GridRow>
  );
}

export { ConnectStore };
