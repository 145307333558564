import React from 'react';
import loadable from '@loadable/component';
import _isFunction from 'lodash/isFunction';
import SSRLoader from 'components/Loading/SSRLoader';
import isSSRTheme from './isSSRTheme';

export default function CustomLazyLoad(
  loadFunc = () => {},
  fallback = <div>Loading...</div>
) {
  if (!_isFunction(loadFunc)) return () => null;
  const isSSR = isSSRTheme();

  return loadable(loadFunc, {
    fallback: isSSR ? <SSRLoader /> : fallback,
    ssr: false
  });
}
