import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { DropdownItem, DropdownMenu } from 'reactstrap';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If } from 'utils';
import { AuthContext } from 'context/AuthContext';
import ADMIN_ROUTUES from 'config/nav/admin/routes';
import { ReactComponent as UserIcon } from 'assets/img/icons/userProfile.svg';
import { ReactComponent as LockIcon } from 'assets/img/icons/new/lock-icon.svg';
// import { ReactComponent as LogoutIcon } from 'assets/img/icons/new/logout-icon.svg';
import { ReactComponent as LogoutIcon } from 'assets/img/icons/logout.svg';
import { useAppContext } from 'utils/hooks';

export default function UserDropdownMenu({
  className = '',
  itemClassName = ''
}) {
  const { push } = useHistory();
  const {
    ROLES,
    userData,
    isExternalUser,
    toggleChangePasswordModal
  } = useContext(AuthContext);
  const { isSSRMode } = useAppContext();

  const email = _get(userData, 'email', '');
  const isAdmin = _get(ROLES, 'admin', false);

  const containerClass = cn(styles.container, className);
  const metaDataClassName = cn(styles.item, styles.metaData, itemClassName);
  const itemClass = cn(styles.item, itemClassName);

  const onLogout = () => push('/logout');
  const gotoAccountDetailsPage = () => {
    push(ADMIN_ROUTUES.ACCOUNT_SETTINGS.ROOT);
  };

  return (
    <DropdownMenu right className={containerClass}>
      <DropdownItem className={metaDataClassName} id="user-data">
        <div className="truncate-text">{email}</div>
      </DropdownItem>
      <If test={!isSSRMode}>
        <If test={isAdmin}>
          <DropdownItem className={itemClass} onClick={gotoAccountDetailsPage}>
            <UserIcon />
            Account Settings
          </DropdownItem>
        </If>
        <If test={!isAdmin && !isExternalUser}>
          <DropdownItem
            className={itemClass}
            onClick={toggleChangePasswordModal}
          >
            <LockIcon />
            Change Password
          </DropdownItem>
        </If>
      </If>
      <DropdownItem className={cn(itemClass, styles.logout)} onClick={onLogout}>
        <LogoutIcon viewBox="0 0 18 18" />
        Logout
      </DropdownItem>
    </DropdownMenu>
  );
}
