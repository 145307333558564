import React, { useContext } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import qs from 'qs';
import _toLower from 'lodash/toLower';

import { AuthContext } from 'context/AuthContext';

const SSLoginPath = 'https://signin.shipstation.com/login';

const ProtectedRoute = ({
  component: Component,
  isSSRMode = false,
  ...routeProps
}) => {
  const {
    ROLES,
    appType,
    userData,
    isLoggedIn,
    configuration,
    changePasswordModal,
    isOnboardingComplete,
    toggleChangePasswordModal,
    passwordChangeRequired
  } = useContext(AuthContext);

  return (
    <Route
      {...routeProps}
      render={props => {
        if (!isLoggedIn) {
          if (isSSRMode) window.location.href = SSLoginPath;
          return <Redirect to="/login" />;
        }
        if (passwordChangeRequired) {
          const path = encodeURIComponent(props.location.pathname);
          return <Redirect to={`/password-change?redirectTo=${path}`} />;
        }
        if (!isOnboardingComplete) {
          if (isSSRMode) window.location.href = SSLoginPath;
          return <Redirect to="/onboarding" />;
        }

        return (
          <Component
            {...props}
            ROLES={ROLES}
            appType={appType}
            userData={userData}
            configuration={configuration}
            toggleChangePasswordModal={toggleChangePasswordModal}
            changePasswordModal={changePasswordModal}
          />
        );
      }}
    />
  );
};

const UnProtectedRoute = ({ component: Component, ...routeProps }) => {
  const { isLoggedIn, login } = useContext(AuthContext);

  return (
    <Route
      {...routeProps}
      render={props => {
        if (isLoggedIn) return <Redirect to="/" />;
        return <Component {...props} login={login} />;
      }}
    />
  );
};

const LoginRoute = ({ component: Component, ...routeProps }) => {
  const { search } = useLocation();
  const { isLoggedIn } = useContext(AuthContext);

  const { app_install } = qs.parse(search, { ignoreQueryPrefix: true });
  const isAppInstall = _toLower(app_install) === 'true';

  return (
    <Route
      {...routeProps}
      render={props => {
        if (isLoggedIn && !isAppInstall) return <Redirect to="/" />;
        return <Component {...props} />;
      }}
    />
  );
};

const LogoutRoute = ({ ...routeProps }) => {
  const { logout } = useContext(AuthContext);

  return (
    <Route
      {...routeProps}
      render={() => {
        logout();
        return <Redirect to="/login" />;
      }}
    />
  );
};

const OnboardingRoute = ({ component: Component, ...routeProps }) => {
  const {
    userData,
    isLoggedIn,
    isOnboardingComplete,
    toggleChangePasswordModal,
    passwordChangeRequired
  } = useContext(AuthContext);

  return (
    <Route
      {...routeProps}
      render={props => {
        const isDev = process.env.REACT_APP_MODE === 'DEVELOPMENT';
        if (!isLoggedIn) return <Redirect to="/login" />;
        if (isOnboardingComplete && !isDev) return <Redirect to="/" />;
        if (passwordChangeRequired) {
          const path = encodeURIComponent(props.location.pathname);
          return <Redirect to={`/password-change?redirectTo=${path}`} />;
        }
        return (
          <Component
            {...props}
            userData={userData}
            toggleChangePasswordModal={toggleChangePasswordModal}
          />
        );
      }}
    />
  );
};

const ForcePasswordChangeRoute = ({ component: Component, ...routeProps }) => {
  const { isLoggedIn, passwordChangeRequired } = useContext(AuthContext);

  return (
    <Route
      {...routeProps}
      render={props => {
        if (!isLoggedIn) return <Redirect to="/login" />;
        if (!passwordChangeRequired) return <Redirect to="/" />;
        return <Component {...props} />;
      }}
    />
  );
};

export {
  LoginRoute,
  LogoutRoute,
  UnProtectedRoute,
  ProtectedRoute,
  OnboardingRoute,
  ForcePasswordChangeRoute
};
