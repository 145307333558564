import { EcommPlatform, UiKit } from 'components';
import React, { useEffect, useMemo } from 'react';
import styles from './index.module.scss';

const RetailerStoreSelection = ({ list, values, setValues, errors }) => {
  const orgList = useMemo(
    () =>
      list
        ? list.map(el => ({
            label: el.value,
            options: el.stores.map(store => ({
              value: store.rr_store_id,
              label: store.name,
              eCommercePlatform: store.ecommerce_platform
            }))
          }))
        : [],
    [list]
  );

  const selectedRetailer = useMemo(() => {
    if (values.retailer_id) {
      for (let retailer of orgList) {
        for (let store of retailer.options) {
          if (store.value === values.retailer_id) {
            return store;
          }
        }
      }
    }
    return null;
  }, [orgList, values.retailer_id]);

  useEffect(() => {
    // If there's exactly one retailer with one store, automatically set it
    if (
      orgList.length === 1 &&
      orgList[0].options.length === 1 &&
      !values.retailer_id
    ) {
      setValues({
        ...values,
        retailer_id: orgList[0].options[0].value
      });
    }
  }, [orgList, setValues, values]);

  // Don't render the select if there's no list, or if there's exactly one retailer with one store
  if (
    !orgList.length ||
    (orgList.length === 1 && orgList[0].options.length === 1)
  ) {
    return null;
  }

  return (
    <UiKit.Select
      placeholder="Select a retailer"
      options={orgList}
      value={selectedRetailer}
      onChange={e =>
        setValues({
          ...values,
          retailer_id: e?.value || null
        })
      }
      error={errors.store}
      inputClassName={styles.selectWrapper}
      formatLabel={({ label, eCommercePlatform }) => (
        <div className="d-flex  align-items-center w-full">
          <EcommPlatform.Icon
            source={eCommercePlatform}
            className={styles.eCommerceIcon}
          />
          <span>{label}</span>
        </div>
      )}
    />
  );
};

export default RetailerStoreSelection;
