import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { Button, Input } from 'reactstrap';

import { isValidEmail } from 'utils/validators';
import RetailerStoreSelection from 'views/Login/components/RetailerSelect/index';

const StepTwo = ({ requestLink, processing, success, email, list }) => {
  const sellerList = useMemo(
    () =>
      Object.keys(list).map(key => ({
        value: key,
        label: key,
        stores: list[key]
      })),
    [list]
  );

  return (
    <Formik
      initialValues={{ email }}
      validate={values => {
        let errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (!isValidEmail(values.email)) {
          errors.email = 'Invalid email address';
        }
        if (
          sellerList.length !== 0 &&
          (!values.retailer_id || values.retailer_id.length < 1)
        ) {
          errors.store = 'Please choose a store';
        }
        return errors;
      }}
      onSubmit={values => {
        const formattedValues = Object.assign({}, values);
        formattedValues.email = formattedValues.email.toLowerCase();
        requestLink(formattedValues);
      }}
    >
      {({ values, errors, handleChange, handleSubmit, setValues }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <Input
              type="email"
              name="email"
              onChange={handleChange}
              className="form-control"
              aria-describedby="emailHelp"
              placeholder="Email"
              value={values.email}
              invalid={errors && errors['email']}
              disabled={success !== null || processing}
            />
          </div>
          <RetailerStoreSelection
            list={sellerList}
            values={values}
            setValues={setValues}
            errors={errors}
          />
          <div className="form-group">
            <Button
              className="login-button"
              type="submit"
              disabled={
                processing ||
                success !== null ||
                (errors && (errors['email'] || errors['retailer_id']))
              }
            >
              {processing
                ? 'Processing...'
                : success !== null
                ? 'Sent'
                : 'Get Reset Link'}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default StepTwo;
