import React from 'react';
import _get from 'lodash/get';
import _capitalize from 'lodash/capitalize';

function getCustomSelectStyles(data) {
  const error = _get(data, 'error', false);
  const isGrouped = _get(data, 'isGrouped', false);

  return {
    container: provided => ({
      ...provided,
      borderRadius: '6px'
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: 'unset',
      border:
        error && !state.isDisabled
          ? '1px solid #da4d4db3'
          : state.isDisabled
          ? '1px solid rgba(12, 19, 26, 0.1)'
          : '1px solid rgba(12, 19, 26, 0.3)',
      minHeight: '44px',
      borderRadius: '6px',
      backgroundColor: state.isDisabled ? 'rgba(12, 19, 26, 0.05)' : '#ffffff',
      cursor: state.isDisabled ? 'not-allowed' : 'unset',
      outline:
        (state.menuIsOpen || state.isFocused) && !state.isDisabled
          ? '1px auto rgba(12, 19, 26, 0.6)'
          : null,
      '&:hover': {
        border: error
          ? '1px solid rgba(255, 121, 121, 0.5)'
          : '1px solid rgba(12, 19, 26, 0.6)',
        boxShadow: 'unset'
      }
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? 'rgba(12, 19, 26, 0.3)' : 'rgba(12, 19, 26, 1)'
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'all 150ms',
      transform: state.isFocused ? 'rotateX(180deg)' : 'rotateX(0)'
    }),
    group: provided => ({
      ...provided,
      padding: '0'
    }),
    groupHeading: provided => ({
      ...provided,
      margin: '0',
      padding: '6px 12px',
      backgroundColor: '#f3f3f3',
      position: 'sticky',
      top: '0',
      textTransform: 'unset'
    }),
    indicatorsContainer: provided => ({
      ...provided,
      cursor: 'pointer'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    placeholder: provided => ({ ...provided, color: '#a6afb8' }),
    menu: provided => ({
      ...provided,
      margin: '0',
      top: 'calc(100% + 4px)',
      backgroundColor: '#ffffff',
      borderRadius: '6px',
      zIndex: '100000',
      boxShadow: '0 0 1px 0 rgba(12, 19, 26, 1)'
    }),
    menuList: provided => ({
      ...provided,
      borderRadius: '6px',
      padding: '0',
      position: 'relative'
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '14px',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      padding: !isGrouped ? '8px 12px' : '8px 12px 8px 28px',
      fontWeight: state.isSelected ? '500' : '400',
      color: state.isDisabled
        ? 'rgba(0, 0, 0, 0.6)'
        : state.isSelected
        ? '#0c131a'
        : '#0c131ab3',
      backgroundColor: state.isDisabled
        ? 'rgba(0, 0, 0, 0.1)'
        : state.isFocused
        ? '#F3F5F7'
        : 'transparent',
      '&:active': {
        backgroundColor: state.isDisabled
          ? 'rgba(0, 0, 0, 0.1)'
          : 'var(--primary-100)'
      },
      '&:focus-visible': {
        outlineColor: 'var(--primary-100)'
      }
    }),
    multiValue: (provided, state) => ({
      ...provided,
      borderRadius: '6px',
      display: 'flex',
      alignItems: 'center',
      gap: '2px',
      padding: '3px 6px',
      border: '1px solid var(--gray-100)',
      borderWidth: '1px',
      borderStyle: 'dashed',
      borderColor: state.isDisabled ? 'rgba(12, 19, 26, 0.2)' : 'transparent',
      backgroundColor: state.isDisabled ? 'transparent' : 'var(--gray-100)'
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: state.isDisabled
        ? 'rgba(12, 19, 26, 0.3)'
        : 'rgba(12, 19, 26, 0.7)',
      padding: '0 !important',
      transform: 'translateY(-1px)'
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      padding: '0',
      color: 'rgba(12, 19, 26, 0.3)',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      '&:hover': {
        color: 'rgba(12, 19, 26, 0.7)',
        backgroundColor: 'transparent'
      }
    }),
    noOptionsMessage: () => ({
      display: 'none'
    })
  };
}

//--------------------------------------------------------------------------

const formatGroupLabel = isGrouped => {
  if (!isGrouped) return undefined;

  return data => (
    <div
      style={{
        color: '#0c131a',
        fontSize: '12px',
        fontWeight: '800'
      }}
    >
      {_capitalize(data.label)}
    </div>
  );
};

//--------------------------------------------------------------------------

export { getCustomSelectStyles, formatGroupLabel };
