import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { ExternalUserBanner } from 'components';

export default function Frame({ children = null, className = '' }) {
  return (
    <div className={cn(styles.container, className)}>
      <ExternalUserBanner />
      <div className={styles.content}>{children}</div>
    </div>
  );
}
