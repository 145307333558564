import { useEffect } from 'react';
import useStopwatch from './useStopwatch';

export default function useCountdown(
  countdownSeconds = 0,
  { autoStart = false } = {}
) {
  const { isRunning, totalSeconds, start, pause, reset } = useStopwatch({
    autoStart
  });

  const secondsRemaining = countdownSeconds - totalSeconds;
  const isDone = secondsRemaining <= 0;

  useEffect(() => {
    if (isRunning && isDone) {
      pause();
    }
  }, [isRunning, isDone, pause]);

  return {
    isDone,
    isRunning,
    seconds: secondsRemaining,
    start,
    pause,
    reset
  };
}
