import React, { useMemo } from 'react';
import cn from 'classnames';
import SSRLoader from './SSRLoader';
import { isSSRTheme } from 'utils';

export default function Loader({ customClass = '' }) {
  const className = useMemo(() => {
    return cn('spinner', { [customClass]: !!customClass });
  }, [customClass]);

  if (isSSRTheme()) {
    return <SSRLoader />;
  }

  return (
    <div className={className}>
      <div className="double-bounce1" />
      <div className="double-bounce2" />
    </div>
  );
}
