import React from 'react';
import _get from 'lodash/get';

import { UiKit } from 'components';
import { StoreUrlInput } from 'views/ConnectStore/components';
import { STORE_URL_PREFIX, STORE_URL_SUFIX } from 'views/ConnectStore/stubs';

export default function Form({
  errors = {},
  values = {},
  isProcessing = false,
  handleBlur = () => {},
  handleChange = () => {},
  handleSubmit = () => {}
}) {
  const storeUrl = _get(values, 'storeUrl', '');
  const error = _get(errors, 'storeUrl', '');

  return (
    <form onSubmit={handleSubmit}>
      <StoreUrlInput
        disablePreview
        name="storeUrl"
        value={storeUrl}
        onBlur={handleBlur}
        onChange={handleChange}
        label="Woo Store URL"
        placeholder="Enter your store URL"
        prefix={STORE_URL_PREFIX.WOOCOMMERCE}
        suffix={STORE_URL_SUFIX.WOOCOMMERCE}
        error={error}
      />
      <UiKit.Button
        type="submit"
        disabled={!storeUrl}
        processing={isProcessing}
        processingText="Connecting..."
        wrapperClassName="mt-4"
        className="w-100"
      >
        Connect your WooCommerce Store
      </UiKit.Button>
    </form>
  );
}
