import _toLower from 'lodash/toLower';

export default function sortPermittedStore(stores = []) {
  if (!Array.isArray(stores)) return [];

  const sortedStores = [...stores];
  return sortedStores.sort((a, b) =>
    _toLower(a.name) < _toLower(b.name) ? -1 : 1
  );
}
