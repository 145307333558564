import React, { useEffect, useMemo, useState } from 'react';
import { DropdownToggle, Dropdown, DropdownMenu } from 'reactstrap';
import cn from 'classnames';
import styles from './index.module.scss';

import { If } from 'utils';
import { useAppContext, useNotifications } from 'utils/hooks';
import { UiKit } from 'components';
import {
  AllNotifications,
  BannerPortal,
  MarkAllAsRead,
  UnreadNotifications
} from './components';
import { ReactComponent as NotificationsIcon } from 'assets/img/icons/new/notifications.svg';
import { ReactComponent as NotificationsDot } from 'assets/img/icons/new/notification_dot.svg';

const TABS = ['Unread', 'All'];

export default function Notifications() {
  const {
    fetchAllNotifications = () => {},
    loadedAllNotifications,
    isFetchingAllNotifications,
    unreadNotificationsList,
    closeDropdownCb = () => {}
  } = useNotifications();
  const { isSSRMode } = useAppContext();
  const [tab, setTab] = useState(TABS[0]);

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  useEffect(() => {
    if (
      tab === TABS[1] &&
      !loadedAllNotifications &&
      !isFetchingAllNotifications
    ) {
      fetchAllNotifications();
    }
  }, [
    tab,
    loadedAllNotifications,
    isFetchingAllNotifications,
    fetchAllNotifications
  ]);

  useEffect(() => {
    closeDropdownCb(setIsNotificationOpen);
  }, [closeDropdownCb]);

  const listClassname = tabValue =>
    cn(styles.notificationList, {
      'd-none': tab === tabValue
    });

  const notificationIconStyle = {
    fill: isNotificationOpen
      ? isSSRMode
        ? '#00331C'
        : '#F45A5A'
      : isSSRMode
      ? 'white'
      : '#45505B'
  };
  const iconContainerStyle = {
    backgroundColor: isNotificationOpen ? '#F9FAFB' : 'inherit'
  };

  const notificationDropdownToggle = () => setIsNotificationOpen(prev => !prev);

  const showNotificationDot = useMemo(
    () =>
      Array.isArray(unreadNotificationsList)
        ? unreadNotificationsList.filter(({ is_read }) => !is_read).length
        : false,
    [unreadNotificationsList]
  );

  return (
    <>
      <BannerPortal />
      <Dropdown isOpen={isNotificationOpen} toggle={notificationDropdownToggle}>
        <DropdownToggle nav className="p-0">
          <div className="position-relative">
            <span
              style={iconContainerStyle}
              className="d-flex align-items-center justify-content-center rounded-circle p-2"
            >
              <NotificationsIcon style={notificationIconStyle} />
            </span>
            <If test={showNotificationDot}>
              <NotificationsDot className={`position-absolute ${styles.dot}`} />
            </If>
          </div>
        </DropdownToggle>
        <DropdownMenu right className={styles.notificationsContainer}>
          <div className={styles.title}>Notifications</div>
          <div className="d-inline-flex justify-content-between w-100">
            <div>
              <UiKit.PillSwitch
                value={tab}
                onChange={setTab}
                className={styles.tabsContainer}
                tabClassName={styles.tab}
                activeTabClassName={styles.activeTab}
              >
                <UiKit.PillSwitch.Pill label="Unread" value={TABS[0]} />
                <UiKit.PillSwitch.Pill label="All" value={TABS[1]} />
              </UiKit.PillSwitch>
            </div>
            <If test={tab === TABS[0]}>
              <MarkAllAsRead />
            </If>
          </div>
          <div className={styles.content} id="scrollableDiv">
            {<UnreadNotifications className={listClassname(TABS[1])} />}
            {<AllNotifications className={listClassname(TABS[0])} />}
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
}
