import React, { useCallback, useRef, useState } from 'react';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If } from 'utils';
import { UiKit } from 'components';
import { StoreTiles } from 'views/ConnectStore/components';
import { getEcomPlatformMap } from 'utils/DICTIONARY';
import { ConnectStoreProvider } from 'views/ConnectStore/context/ConnectStoreContext';
import { Shopify, Woocommerce, Bigcommerce } from 'views/ConnectStore/pages';
import { useMutation } from 'utils/hooks';
import { STEPS } from './stubs';

export default function ConnectStoreModal({
  isOpen = false,
  onClose = () => {}
}) {
  const [step, setStep] = useState(STEPS.ECOMM_PLATFORM);
  /** @type {[import('utils/types').EcommPlatformSource | null, React.Dispatch<React.SetStateAction<import('utils/types').EcommPlatformSource | null>>]} */
  const [ecommPlatform, setEcommPlatform] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const labelMap = useRef(getEcomPlatformMap()).current;

  const title =
    step === STEPS.ECOMM_PLATFORM
      ? 'Connect New Store'
      : `Connect ${_get(labelMap, ecommPlatform)}`;

  const { mutate } = useMutation('seller.stores.post', 'POST', {
    errorMessage: 'Unable to connect the store. Please try again later.',
    payload: () => ({
      ecommerce_platform: ecommPlatform,
      redirect_url: '/account-settings'
    }),
    onSuccess: data => (window.location = _get(data, 'ecom_redirect_url')),
    onError: () => setIsConnecting(false)
  });

  const onStoreSelect = useCallback(id => {
    setEcommPlatform(id);
    setStep(STEPS.STORE_CONNECT);
  }, []);

  const onGotoStoreSelect = useCallback(() => {
    setEcommPlatform(null);
    setStep(STEPS.ECOMM_PLATFORM);
  }, []);

  const onStoreConnect = useCallback(
    (_, data) => {
      setIsConnecting(true);
      mutate({ store: _get(data, 'shop', '').trim() });
    },
    [mutate]
  );

  const onClosed = useCallback(() => setStep(STEPS.ECOMM_PLATFORM), []);

  return (
    <UiKit.Modal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      onClosed={onClosed}
    >
      <If test={step === STEPS.ECOMM_PLATFORM}>
        <StoreTiles onClick={onStoreSelect} />
      </If>
      <If test={step === STEPS.STORE_CONNECT}>
        <ConnectStoreProvider
          store={ecommPlatform}
          className={styles.content}
          isConnecting={isConnecting}
          onConnect={onStoreConnect}
          onCancel={onGotoStoreSelect}
        >
          {ecommPlatform === 'SHOPIFY' && <Shopify />}
          {ecommPlatform === 'WOOCOMMERCE' && <Woocommerce />}
          {ecommPlatform === 'BIGCOMMERCE' && <Bigcommerce />}
        </ConnectStoreProvider>
      </If>
    </UiKit.Modal>
  );
}
