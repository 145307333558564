import React from 'react';
import _get from 'lodash/get';

import StoreSelect from './StoreSelect';
import { useStoreSwitch } from 'utils/hooks';
import StoreSwitchModal from './StoreSwitchModal';

export default function StoreSwitch({ className = '', itemClassName = '' }) {
  const {
    storeData,
    isSwitching,
    isValidating,
    isProcessing,
    onCloseModal,
    onStoreSwitch
  } = useStoreSwitch();

  return (
    <>
      <StoreSelect
        menuClassName={className}
        menuItemClassName={itemClassName}
        onSelect={onStoreSwitch}
      />
      <StoreSwitchModal
        isOpen={!!storeData}
        storeName={_get(storeData, 'name')}
        isSwitching={isSwitching}
        isValidating={isValidating}
        isProcessing={isProcessing}
        onClose={onCloseModal}
      />
    </>
  );
}
