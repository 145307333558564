import React, { useReducer, useEffect } from 'react';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If, logError } from 'utils';
import { CustomToast, UiKit } from 'components';
import ReasonBody from './ActionForms/ReasonBody';
import { reducer } from './reducer';
import { cancelRequest, holdRequest } from './services';
import { updateRecentlyProcessed } from './methods';
import { COPIES, REASONS, HOLD } from './stubs';
import { OS_STATUS } from 'dictionary';
import { useEcomPlatform } from 'utils/hooks';

const CANCELLED_KEY = 'temp__cancelled';
const INITIAL_STATE = {
  reason: [],
  reasonInvalid: false,
  otherReasonInvalid: false,
  otherReason: '',
  options: [],
  isProcessing: false
};

export default function CancelHoldRequest({
  data = null,
  isOpen = false,
  toggle = () => {},
  done = () => {},
  isCentered = false
}) {
  const { ecommPlatformSource, ecommPlatformName } = useEcomPlatform();

  const [cancelHoldData, dispatch] = useReducer(reducer, INITIAL_STATE);
  const type = _get(data, 'type', '');
  const osId = _get(data, 'orderServiceID', '');
  const osStatus = _get(data, 'orderServiceStatus', '');

  const isPendingPayment = osStatus === OS_STATUS.PENDING_PAYMENT_COMPLETION;

  let copy;
  useEffect(() => {
    const setOptions = () => {
      if (type === 'cancel') {
        dispatch({
          type: 'setReasonOptions',
          reason: REASONS[0],
          options: REASONS
        });
      }
      if (type === 'hold') {
        dispatch({ type: 'setReasonOptions', reason: HOLD[0], options: HOLD });
      }
    };
    setOptions();
  }, [type]);

  if (type === 'cancel') {
    copy = COPIES['cancel'];
  }

  if (type === 'hold') {
    let {
      extra: { holdVal }
    } = data;
    copy = COPIES[holdVal ? 'onHold' : 'offHold'];
  }

  const toggleModal = () => toggle(null);

  const handleConfirm = () => {
    const { otherReason, reason } = cancelHoldData;
    const holdVal = _get(data, 'extra.holdVal');
    if (
      (reason.value === 'other' && otherReason.length < 10) ||
      (holdVal === false && otherReason.length < 10)
    ) {
      return dispatch({ type: 'setOtherReasonInvalid', val: true });
    }

    if (type === 'cancel') {
      cancel(osId, cancelHoldData);
    } else if (type === 'hold') {
      hold(osId, cancelHoldData);
    }
  };

  const hold = (orderServiceID, reasonData) => {
    const holdVal = _get(data, 'extra.holdVal');
    dispatch({ type: 'setProcessing', val: true });
    if (!orderServiceID) return null;

    const { otherReason, reason } = reasonData;
    const holdMsg = holdVal
      ? reason.label === 'Other'
        ? otherReason
        : reason.label
      : otherReason; //If its false get input value
    holdRequest(
      {
        routeParam: orderServiceID,
        message: holdMsg,
        hold: holdVal
      },
      result => {
        const successMsg = _get(result, 'displayMessage.message');
        updateRecentlyProcessed(CANCELLED_KEY, orderServiceID);
        dispatch({ type: 'setProcessing', val: false });
        dispatch({ val: INITIAL_STATE });
        CustomToast({
          msg: !successMsg ? 'Successfully updated' : successMsg,
          type: 'success'
        });
        done();
      },
      err => {
        logError(err);
        dispatch({ type: 'setProcessing', val: false });
        const errMessage = _get(err, 'displayMessage.message');
        CustomToast({
          isNotified: err.notified,
          msg:
            err.statusCode === 304
              ? 'Request is already cancelled'
              : !errMessage
              ? 'Something went wrong'
              : errMessage,
          type: 'error'
        });
      }
    );
  };
  const cancel = (orderServiceID, cancelData) => {
    dispatch({ type: 'setProcessing', val: true });
    if (!orderServiceID) return null;

    const { otherReason, reason } = cancelData;
    const canceledMsg = reason.label === 'Other' ? otherReason : reason.label;
    cancelRequest(
      {
        routeParam: orderServiceID,
        cancellation_reason: canceledMsg
      },
      result => {
        let successMsg = _get(result, 'displayMessage.message');
        updateRecentlyProcessed(CANCELLED_KEY, orderServiceID);
        dispatch({ type: 'setProcessing', val: false });
        dispatch({ val: INITIAL_STATE });
        CustomToast({
          msg: !successMsg ? 'Successfully cancelled' : successMsg,
          type: 'success'
        });
        done();
      },
      err => {
        let errMessage = _get(err, 'displayMessage.message');
        logError(err);
        dispatch({ type: 'setProcessing', val: false });
        CustomToast({
          isNotified: err.notified,
          msg:
            err.statusCode === 304
              ? 'Request is already cancelled'
              : !errMessage
              ? 'Something went wrong'
              : errMessage,
          type: 'error'
        });
      }
    );
  };
  let { isProcessing } = cancelHoldData;
  return (
    <UiKit.Modal
      isOpen={isOpen}
      title={copy.header}
      onClose={toggleModal}
      centered={isCentered}
      onConfirm={handleConfirm}
      confirmBtnType="danger"
      isProcessing={isProcessing}
      confirmBtnText={copy.buttonTitle}
      confirmBtnProcessingText={copy.processingButtonTitle}
      modalClassName="confirmDialog"
      headerClassName="confirmCancel__header pl-0 pr-0 pt-0 pb-0"
      contentClassName="confirmDialog-content"
    >
      <h5 className="mt-3">{copy.title}</h5>
      <p className="mb-2">{copy.subtitle}</p>
      <If test={isPendingPayment}>
        <div className={styles.alert}>
          <span role="img" aria-label="info">
            💡
          </span>
          <p>
            We will attempt to cancel{' '}
            <If test={ecommPlatformSource === 'SHOPIFY'}>
              the draft order and
            </If>{' '}
            the payment link that the shopper received but sometimes{' '}
            {ecommPlatformName} does not acknowledge our request. Please ensure
            shopper does not end up making the payment after you've cancelled
            the request.
          </p>
        </div>
      </If>
      <ReasonBody state={cancelHoldData} dispatch={dispatch} data={data} />
    </UiKit.Modal>
  );
}
