import React, { useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { DropdownToggle, Nav, UncontrolledButtonDropdown } from 'reactstrap';
import cn from 'classnames';
import _get from 'lodash/get';
import _startCase from 'lodash/startCase';
import _startsWith from 'lodash/startsWith';
import styles from './index.module.scss';

import { UiKit, StoreSwitch } from 'components';
import RoleSwitch from '../RoleSwitch';
import UserDropdownMenu from '../UserDropdownMenu';
import { AuthContext } from 'context/AuthContext';
import { Notifications } from './components';
import { If } from 'utils';
import { useAppContext } from 'utils/hooks';
import Logo from 'containers/DefaultLayout/Sidebar/Logo';

export default function DefaultHeader() {
  const { userData, appType, ROLES } = useContext(AuthContext);
  const { pathname } = useLocation();

  const isWarehouse = _get(ROLES, 'warehouse', false);
  const isStore = _get(ROLES, 'store', false);
  const isAdmin = _get(ROLES, 'admin', false);

  const redirectLogoLink = useCallback(() => {
    if (pathname === '/w' || _startsWith(pathname, '/w/')) return 'warehouse';
    if (pathname === '/s' || _startsWith(pathname, '/s/')) return 'store';

    if (isAdmin) return '/';
    if (isStore) return '/s';
    if (isWarehouse) return '/w';

    return '/';
  }, [pathname, isAdmin, isStore, isWarehouse]);

  const { isSSRMode } = useAppContext();

  const firstName = _get(userData, 'firstName', '');
  const lastName = _get(userData, 'lastName', '');
  const avatarPicUrl = _get(userData, 'avatarPicUrl', '');

  const avatar = avatarPicUrl
    ? avatarPicUrl
    : `https://ui-avatars.com/api/?name=${firstName}+${lastName}&background=${
        isSSRMode ? 'ffffff&rounded=true' : 'fbd3cf'
      }`;

  return (
    <UiKit.GridRow
      templateColumns="200px 1fr"
      gap="24px"
      className={styles.container}
    >
      <Logo linkTo={redirectLogoLink} />
      <div className={styles.content}>
        <div className={styles.cell}>
          <Nav navbar className={cn(styles.userNav, styles.userNavLeft)}>
            <StoreSwitch className={styles.menu} itemClassName={styles.item} />
          </Nav>
          <If test={!isSSRMode}>
            <RoleSwitch
              menuClassName={styles.menu}
              itemClassName={styles.item}
            />
          </If>
        </div>
        <div className={styles.cell}>
          <If test={appType.retex}>
            <Notifications />
          </If>
          <Nav navbar className={cn(styles.userNav, styles.userNavRight)}>
            <UncontrolledButtonDropdown>
              <DropdownToggle nav className={styles.userInfo} caret>
                <img src={avatar} alt="user" />
                <span>
                  {_startCase(firstName)}
                  {lastName && ' ' + _startCase(lastName)}
                </span>
              </DropdownToggle>
              <UserDropdownMenu
                className={styles.menu}
                itemClassName={styles.item}
              />
            </UncontrolledButtonDropdown>
          </Nav>
        </div>
      </div>
    </UiKit.GridRow>
  );
}
