import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import _get from 'lodash/get';
import _toLower from 'lodash/toLower';
import styles from './index.module.scss';

import { EcommPlatform, UiKit } from 'components';
import { getEcomPlatformMap } from 'utils/DICTIONARY';

/** @type {import("utils/types").EcommPlatformSource[]} */
const PLATFORMS = ['SHOPIFY', 'WOOCOMMERCE', 'BIGCOMMERCE'];

export default function StoreTiles({ onClick = () => {} }) {
  const { search } = useLocation();

  const labelMap = useRef(getEcomPlatformMap()).current;

  const tiles = PLATFORMS.map(platform => {
    const id = _toLower(platform);
    const label = _get(labelMap, platform, '');

    return (
      <div
        onClick={() => onClick(platform, { search })}
        key={id}
        className={cn(styles.card, styles[id])}
      >
        <EcommPlatform.Icon source={platform} className={styles.icon} />
        <p className={styles.title}>{label}</p>
      </div>
    );
  });

  return <UiKit.GridRow gap="16">{tiles}</UiKit.GridRow>;
}
