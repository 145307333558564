import { useContext, useMemo } from 'react';
import _get from 'lodash/get';

import { isSSRTheme } from 'utils';
import { AuthContext } from 'context/AuthContext';

export default function useNexusConfg() {
  const { masterData, storeProperties } = useContext(AuthContext);

  const retailerId = _get(storeProperties, 'retailer_id', '');
  const storeGuid = _get(storeProperties, 'store_unique_guid', '');

  const axiosConfig = useMemo(() => {
    const headers = {
      'X-Cookie-Authentication': true
    };

    if (isSSRTheme()) {
      headers['Seller-Store-Guid'] = storeGuid;
    } else {
      headers['Rr-Store-Id'] = retailerId;
    }

    return {
      baseURL: process.env.REACT_APP_BASE_URL,
      withCredentials: true,
      headers
    };
  }, [storeGuid, retailerId]);

  return {
    axiosConfig,
    masterData,
    storeProperties
  };
}
