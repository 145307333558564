import React, { useRef } from 'react';
import Flatpickr from 'react-flatpickr';
import cn from 'classnames';
import uuid from 'uuid/v4';
import _isString from 'lodash/isString';
import 'flatpickr/dist/flatpickr.css';
import styles from './index.module.scss';

import { ReactComponent as CalendarIcon } from 'assets/img/icons/calendar.svg';

/**
 * @description Flatpicker docs: https://flatpickr.js.org/
 * @description Options: https://flatpickr.js.org/options/
 * @param {import('react-flatpickr').DateTimePickerProps & {
 *   label: string,
 *   error: string,
 *   className: string,
 *   errorClass: string,
 *   labelClassName: string,
 *   inputClassName: string,
 *   inputWrapClassName: string,
 * }} props
 */
export default function DatePicker({
  label = '',
  value = null,
  onChange = () => {},
  error = '',
  disabled = false,
  className = '',
  errorClass = '',
  labelClassName = '',
  inputClassName = '',
  inputWrapClassName = '',
  placeholder = 'Select Date',
  ...flatpickerProps
}) {
  const inputId = useRef(uuid()).current;
  const showError = error && _isString(error) && !disabled;

  return (
    <div className={cn(styles.container, className)} data-disabled={disabled}>
      {label && (
        <label htmlFor={inputId} className={cn(styles.label, labelClassName)}>
          {label}
        </label>
      )}
      <div className={styles.row}>
        <div className={cn(styles.inputWrapper, inputWrapClassName)}>
          <Flatpickr
            id={inputId}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={cn(styles.input, inputClassName)}
            {...flatpickerProps}
          />
          <CalendarIcon className={styles.icon} />
        </div>
      </div>
      {showError && (
        <p className={cn('uiKit__input--error', errorClass)}>{error}</p>
      )}
    </div>
  );
}
