import React from 'react';
import { useLocation } from 'react-router-dom';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';

import { preventErrorLog } from 'utils/methods';
import NotFound from './NotFound';
import ErrorView from './ErrorView';

class ErrorObserver extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: this.props.hasError };
  }

  componentDidUpdate(prevProps) {
    if (this.state.hasError && prevProps.pathname !== this.props.pathname) {
      return this.setState({ hasError: false });
    }
    if (this.props.hasError && !this.state.hasError) {
      this.setState({ hasError: true });
    }
  }

  componentDidCatch(error, info) {
    this.handleError(error, info);
  }

  handleError(error, info) {
    if (preventErrorLog() || this.state.hasError) return;

    this.setState({ hasError: true });
    LogRocket.captureException(error, {
      extra: { componentStack: info.componentStack }
    });
    Sentry.withScope(scope => {
      Object.keys(info).forEach(key => scope.setExtra(key, info[key]));
      Sentry.captureException(error);
    });
  }

  render() {
    const {
      errorObject,
      children = null,
      homeButton = false,
      allowRefresh = false
    } = this.props;

    if (!this.state.hasError) return children;

    let message = undefined;
    if (typeof errorObject === 'string') {
      message = errorObject;
    } else if (typeof errorObject === 'object' && errorObject !== null) {
      message =
        typeof errorObject.displayMessage === 'string'
          ? errorObject.displayMessage
          : 'Something went wrong. Please try again later.';
    }
    if (
      typeof errorObject === 'object' &&
      errorObject !== null &&
      errorObject.statusCode === 404 &&
      errorObject.method === 'get'
    ) {
      return <NotFound />;
    }
    return (
      <ErrorView
        message={message}
        homeButton={homeButton}
        allowRefresh={allowRefresh}
      />
    );
  }
}

export default function Observer({
  hasError = false,
  errorObject = null,
  homeButton = false,
  allowRefresh = true,
  children = null
}) {
  const { pathname } = useLocation();

  return (
    <ErrorObserver
      pathname={pathname}
      hasError={hasError}
      errorObject={errorObject}
      homeButton={homeButton}
      allowRefresh={allowRefresh}
    >
      {children}
    </ErrorObserver>
  );
}
