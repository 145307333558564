import React, { useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';

import { ReactComponent as InfoIcon } from 'assets/img/alert-icons/info.svg';

export default function Label({ text = '', helptext = '', className = '' }) {
  const id = useRef(`label-tooltip-${uuidv4()}`).current;

  return (
    <div className={cn('uiKit__element-label', className)}>
      {text}
      {helptext && (
        <>
          <InfoIcon id={id} className="uiKit__element-icon" />
          <UncontrolledTooltip
            target={id}
            placement="top"
            className="uiKit__element-tooltip"
          >
            {helptext}
          </UncontrolledTooltip>
        </>
      )}
    </div>
  );
}
