import { useCallback } from 'react';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

import { useFetch, useStoreConnection } from 'utils/hooks';
import { modifyStores } from '../methods';

export default function useAddOnStoreConnect(sellerId = '') {
  const { isConnecting, connectingStore, onConnect } = useStoreConnection();

  const { data: stores, isError, isLoading, fetchData } = useFetch(
    'addOnStores',
    {
      initialData: [],
      isPublicAPI: true,
      routeParam: sellerId,
      errorMessage: 'Unable to fetch stores at the moment.',
      onTransform: data => modifyStores(_isArray(data) ? data : [])
    }
  );

  const connect = useCallback(
    store => {
      if (!store) return;
      const storeUrl = _get(store, 'store_url', '');
      const ecommPlatform = _get(store, 'ecommerce_platform', '');
      onConnect(ecommPlatform, { shop: storeUrl });
    },
    [onConnect]
  );

  return {
    stores,
    isError,
    isLoading,
    isConnecting,
    connectingStore,
    connect,
    onReload: fetchData
  };
}
