import { useEffect, useState } from 'react';

import { localStore, isSSRTheme } from 'utils';
// import useAppContext from './useAppContext';
import useMutation from './useMutation';

export default function useStoreSwitch() {
  const [storeData, setStoreData] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isSwitching, setIsSwitching] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  // DEPRECATED CODE - 21/02/2025
  // const [needPassword, setNeedPassword] = useState(false);
  // const [password, setPassword] = useState('');
  // const [passwordError, setPasswordError] = useState('');
  // const passwordInputRef = useRef(null);

  // const { isSSRMode } = useAppContext();

  // const email = _get(userData, 'email', '');

  const { mutate: onVerify, isProcessing: isValidating } = useMutation(
    'retailerVerify',
    'POST',
    {
      errorMessage: 'Unable to verify the store. Please try again.',
      payload: () => {},
      onSuccess: () => setIsSwitching(true),
      onError: err => {
        setIsError(true);
        // if (isSSRMode) return;
        // setNeedPassword(err.statusCode % 400 < 100);
      }
    }
  );

  useEffect(() => {
    if (isSwitching && storeData) {
      const timer = setTimeout(() => {
        localStore.put('rrloggedInStoreName', storeData.name);
        const idKey = isSSRTheme()
          ? 'rrloggedInStoreGuId'
          : 'rrloggedInStoreId';
        localStore.put(idKey, storeData.id);
        window.location = '/';
        clearTimeout(timer);
      }, [500]);
    }
  }, [storeData, isSwitching]);

  // useEffect(() => {
  //   if (needPassword && passwordInputRef.current) {
  //     passwordInputRef.current.focus();
  //   }
  // }, [needPassword]);

  const onStoreSwitch = ({ id, name } = {}, callback = () => {}) => {
    if (!id) return;
    const key = isSSRTheme() ? 'seller_store_guid' : 'rr_store_id';
    onVerify({ [key]: id }, callback);
    setStoreData({ id, name });
  };

  // const onLogin = e => {
  //   e.preventDefault();
  //   if (!password) return setPasswordError('Please enter the password');
  //   setIsLoggingIn(true);
  //   login(
  //     { email, password, retailer_name: toStore.name },
  //     () => {
  //       setIsSwitching(true);
  //       // setNeedPassword(false);
  //     },
  //     () => {
  //       setPasswordError('Incorrect Password! Please try again.');
  //       const timer = setTimeout(() => {
  //         if (passwordInputRef) {
  //           passwordInputRef.current.focus();
  //           passwordInputRef.current.select();
  //         }
  //         clearTimeout(timer);
  //       }, 0);
  //     },
  //     () => setIsLoggingIn(false)
  //   );
  // };

  // const onPasswordChange = (_, val) => {
  //   setPassword(val);
  //   setPasswordError('');
  // };

  const onCloseModal = () => {
    setStoreData(null);
    // setPassword('');
    // setPasswordError('');
    setIsSwitching(false);
    setIsLoggingIn(false);
    // setNeedPassword(false);
  };

  return {
    storeData,
    isError,
    isValidating,
    isSwitching,
    isLoggingIn,
    isProcessing: isSwitching || isLoggingIn || isValidating,
    // needPassword,
    // password,
    // passwordError,
    onStoreSwitch,
    onCloseModal
    // onLogin,
    // onPasswordChange
  };
}
