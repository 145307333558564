import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { If } from 'utils';

export default function Cell({
  label = '',
  isMuted = false,
  className = '',
  labelClassName = '',
  contentClassName = '',
  children = null
}) {
  return (
    <div className={cn(styles.cell, className)}>
      <If test={!!label}>
        <span className={cn(styles.label, labelClassName)}>{label}</span>
      </If>
      <div
        data-disabled={isMuted}
        className={cn(styles.action, contentClassName)}
      >
        {children}
      </div>
    </div>
  );
}
