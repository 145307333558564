import React from 'react';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

import { PageErrorUi } from 'components';
import { Table, TableHead } from 'components/Tables';
import Rows from './Rows';
import Loader from './Loader';
import { useFetch, useStoreSwitch } from 'utils/hooks';
import { TABLE_HEADINGS } from './stubs';

export default function StoresTable() {
  const { storeData, isProcessing, onStoreSwitch } = useStoreSwitch();

  const processingStoreId = _get(storeData, 'id', '');

  const { data, isError, isLoading, fetchData } = useFetch(
    'seller.stores.get',
    {
      errorMessage:
        'Unable to load the stores at the moment. Please try later.',
      initialData: [],
      onTransform: data => (_isArray(data) ? data : [])
    }
  );

  return (
    <Table>
      <TableHead headings={TABLE_HEADINGS} />
      <PageErrorUi isError={isError} onReload={() => fetchData()}>
        <Loader isLoading={isLoading}>
          <Rows
            stores={data}
            onStoreSwitch={onStoreSwitch}
            isDisabled={isProcessing}
            processingId={isProcessing && processingStoreId}
          />
        </Loader>
      </PageErrorUi>
    </Table>
  );
}
